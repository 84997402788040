import React, { useState } from 'react';
import { Form, FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { object, string } from 'yup';

import { AppDispatch, RootState } from 'redux/store';
import CombinedSelectors from 'redux/CombinedSelectors';
import CombinedActions from 'redux/CombinedActions';
import { LoginReduxParams } from 'redux/slices/auth/types';
import NavActions from 'lib/NavActions';
import CTAButton from 'components/CTAButton';

import LoginTabContentStyles from './_LoginTabContentStyles.module.scss';

interface LoginTabContent {
    loginIsLoading: boolean;
    loginError: string;
    login: (params: LoginReduxParams) => void;
}

const LoginTabContent = (props: LoginTabContent): JSX.Element => {
    const {
        loginIsLoading,
        loginError,
        login,
    } = props;

    const {
        navToForgotPassword,
    } = NavActions;

    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');

    const [emailInvalid, setEmailInvalid] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);

    const loginFormSchema = object({
        email: string().required().email().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g),
        password: string().min(6).max(24).required(),
    });

    const handleLoginClick = async () => {
        const validateEmailPromise = new Promise((resolve, reject) => {
            try {
                loginFormSchema.validateAt('email', { email: emailAddress })
                    .then(
                        (value) => {
                            setEmailInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setEmailInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        const validatePasswordPromise = new Promise((resolve, reject) => {
            try {
                loginFormSchema.validateAt('password', { password })
                    .then(
                        (value) => {
                            setPasswordInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setPasswordInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        await Promise.allSettled([validateEmailPromise, validatePasswordPromise])
            .then((results) => {
                const fieldInvalid = results.find((result) => result.status === 'rejected');

                if (!fieldInvalid) {
                    login({ email: emailAddress, password });
                }
            });
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') handleLoginClick();

        if (e.currentTarget.name === 'email' && emailInvalid) setEmailInvalid(false);
        if (e.currentTarget.name === 'password' && passwordInvalid) setPasswordInvalid(false);
    };

    return (
        <div className={LoginTabContentStyles.container}>
            <Form>
                <FormGroup className={LoginTabContentStyles['form-group']}>
                    <Label
                        className={LoginTabContentStyles.label}
                        for='email'
                    >
                        Email
                    </Label>

                    <Input
                        type='text'
                        name='email'
                        placeholder='enter email address'
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.currentTarget.value)}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                        invalid={emailInvalid || undefined}
                        autoCapitalize='off'
                        inputMode='email'
                    />

                    <FormFeedback invalid={emailInvalid ? 'true' : undefined}>
                        Invalid email address!
                    </FormFeedback>
                </FormGroup>

                <FormGroup className={LoginTabContentStyles['form-group']}>
                    <Label
                        className={LoginTabContentStyles.label}
                        for='password'
                    >
                        Password
                    </Label>

                    <Input
                        type='password'
                        name='password'
                        placeholder='enter password'
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                        invalid={passwordInvalid || undefined}
                    />

                    <FormFeedback invalid={passwordInvalid ? 'true' : undefined}>
                        Invalid password!
                    </FormFeedback>
                </FormGroup>
            </Form>

            <a
                onClick={(e) => {
                    e.preventDefault();
                    navToForgotPassword();
                }}
                href='forgot-password'
            >
                Forgot Password?
            </a>

            <br />

            <CTAButton
                label='Login'
                onClick={handleLoginClick}
                isLoading={loginIsLoading}
            />

            {loginError
                && (
                    <p className={LoginTabContentStyles.error}>
                        {loginError}
                    </p>
                )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    loginIsLoading: CombinedSelectors.auth.getLoginAttempting(state.auth),
    loginError: CombinedSelectors.auth.getLoginError(state.auth),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    login: (params: LoginReduxParams) => dispatch(CombinedActions.authLoginAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginTabContent);
