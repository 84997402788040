import React, { useEffect, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store';
import CombinedActions from 'redux/CombinedActions';
import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import CombinedSelectors from 'redux/CombinedSelectors';
import { ContainerConstants, NavigationConstants } from 'lib/Constants';

import UserProfileDropdown from 'components/UserProfileDropdown';
import MobileMenuDropdownStyles from './_MobileMenuDropdownStyles.module.scss';

interface MobileMenuDropdownProps {
    authToken: string;
    getUserInfo: () => void;
}

const MobileMenuDropdown = (props: MobileMenuDropdownProps): JSX.Element => {
    const {
        authToken,
        getUserInfo,
    } = props;

    let isAuthenticated = authToken;

    if (!isAuthenticated) isAuthenticated = Utils.Auth.getAuthToken() || '';

    const {
        navToLogin,
        navToHomeHowToJoinSection,
        navToHomeFAQSection,
        navToProducts,
        navToTermsAndConditions,
        navToCooperCompanies,
    } = NavActions;

    const {
        homePage: {
            howToJoinContainerId,
            faqContainerId,
        },
    } = ContainerConstants;

    const {
        cooperCompaniesPage,
    } = NavigationConstants;

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const dropdownToggle = () => setDropdownIsOpen((prevState) => !prevState);

    useEffect(() => {
        getUserInfo();
    }, []);

    useEffect(() => {
        if (dropdownIsOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [dropdownIsOpen]);
    return (
        <Dropdown
            isOpen={dropdownIsOpen}
            toggle={dropdownToggle}
            className={MobileMenuDropdownStyles.dropdown}
        >
            <DropdownToggle
                size='lg'
                className={`${MobileMenuDropdownStyles.toggle} ${dropdownIsOpen && MobileMenuDropdownStyles.isOpen}`}
            >
                <img
                    height={15}
                    alt='menu-logo'
                />
            </DropdownToggle>

            <DropdownMenu
                className={MobileMenuDropdownStyles['dropdown-menu']}
                end
            >
                <DropdownItem
                    className={MobileMenuDropdownStyles['dropdown-item']}
                    onClick={navToHomeHowToJoinSection}
                    href={`/#${howToJoinContainerId}`}
                >
                    How To Join
                </DropdownItem>

                <DropdownItem
                    className={MobileMenuDropdownStyles['dropdown-item']}
                    onClick={navToHomeFAQSection}
                    href={`/#${faqContainerId}`}
                >
                    FAQ
                </DropdownItem>

                <DropdownItem
                    className={MobileMenuDropdownStyles['dropdown-item']}
                    onClick={navToProducts}
                >
                    Products
                </DropdownItem>

                <DropdownItem
                    className={MobileMenuDropdownStyles['dropdown-item']}
                    onClick={navToTermsAndConditions}
                >
                    Terms & Conditions
                </DropdownItem>

                {isAuthenticated
                    ? (
                        <UserProfileDropdown
                            isMobileMenu
                            onNavigate={() => setDropdownIsOpen(false)}
                        />
                    )
                    : (
                        <DropdownItem
                            onClick={navToLogin}
                            className={MobileMenuDropdownStyles['dropdown-item__green']}
                        >
                            Login / Sign Up
                        </DropdownItem>
                    )}
                <div className={MobileMenuDropdownStyles['mobile-menu-copyright-container']}>
                    © 2023 CooperVision | Part of

                    {' '}

                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            navToCooperCompanies();
                        }}
                        href={cooperCompaniesPage}
                    >
                        CooperCompanies
                    </a>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
};

const mapStateToProps = (state: RootState) => ({
    authToken: CombinedSelectors.auth.getAuthToken(state.auth),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUserInfo: () => dispatch(CombinedActions.profileGetUserInfoAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenuDropdown);
