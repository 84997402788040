import React, { useEffect, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { AppDispatch, RootState } from 'redux/store';
import { ProfilePageTabReduxTypes } from 'redux/slices/profile/types';
import CombinedActions from 'redux/CombinedActions';
import NavActions from 'lib/NavActions';
import Utils from 'lib/Utils';
import { GetUserInfoAPIResponse } from 'api/ProfileBase';
import CombinedSelectors from 'redux/CombinedSelectors';

import UserProfileDropdownStyles from './_UserProfileDropdownStyles.module.scss';

interface UserProfileDropdownProps {
    userInfo: GetUserInfoAPIResponse;
    userInfoIsLoading: boolean;
    isMobileMenu?: boolean;
    onNavigate?: () => void;
    setProfilePageCurrentTab: (tab: ProfilePageTabReduxTypes) => void;
    authClearAuthToken: () => void;
    getUserInfo: () => void;
}

const UserProfileDropdown = (props: UserProfileDropdownProps): JSX.Element => {
    const {
        userInfo,
        userInfoIsLoading,
        isMobileMenu,
        onNavigate,
        setProfilePageCurrentTab,
        authClearAuthToken,
        getUserInfo,
    } = props;

    const {
        navToProfile,
        navToHome,
    } = NavActions;

    const {
        Auth: {
            clearAuthToken,
        },
    } = Utils;

    const {
        fullName,
    } = userInfo;

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const dropdownToggle = () => setDropdownIsOpen((prevState) => !prevState);

    const [voucherDropdownIsOpen, setVoucherDropdownIsOpen] = useState(false);

    useEffect(() => {
        if (!userInfo) {
            getUserInfo();
        }
    }, []);

    return (
        <Dropdown
            isOpen={dropdownIsOpen}
            toggle={dropdownToggle}
            className={UserProfileDropdownStyles.dropdown}
        >
            <DropdownToggle
                size='lg'
                caret
                className={`${isMobileMenu ? UserProfileDropdownStyles['mobile-menu'] : UserProfileDropdownStyles.toggle} ${dropdownIsOpen && UserProfileDropdownStyles.isOpen}`}
            >
                <div className={userInfoIsLoading ? 'loading-skeleton' : UserProfileDropdownStyles.initials}>
                    {fullName.substring(0, 1).toUpperCase()}
                </div>
                {isMobileMenu && (
                    <div className={UserProfileDropdownStyles.fullname}>
                        {fullName}
                    </div>
                )}
            </DropdownToggle>

            <DropdownMenu
                className={UserProfileDropdownStyles['dropdown-menu']}
            >
                <DropdownItem
                    className={UserProfileDropdownStyles['dropdown-item']}
                    onClick={() => {
                        setProfilePageCurrentTab('Upload Receipt');
                        navToProfile();
                        if (onNavigate) onNavigate();
                    }}
                >
                    Upload Receipt
                </DropdownItem>

                <Dropdown
                    isOpen={voucherDropdownIsOpen}
                    toggle={() => setVoucherDropdownIsOpen(!voucherDropdownIsOpen)}
                    onMouseEnter={() => setVoucherDropdownIsOpen(true)}
                    onMouseLeave={() => setVoucherDropdownIsOpen(false)}
                    direction={isMobileMenu ? 'down' : 'end'}
                    className={UserProfileDropdownStyles['embedded-dropdown']}
                >
                    <DropdownToggle
                        className={`${UserProfileDropdownStyles.toggle} ${UserProfileDropdownStyles['embedded-dropdown-toggle']} ${voucherDropdownIsOpen && UserProfileDropdownStyles.isOpen}`}
                        caret
                    >
                        Voucher(s)
                    </DropdownToggle>

                    <DropdownMenu
                        className={UserProfileDropdownStyles['embedded-dropdown-toggle-menu']}
                    >
                        <DropdownItem
                            className={UserProfileDropdownStyles['dropdown-item']}
                            onClick={() => {
                                dropdownToggle();
                                setProfilePageCurrentTab('Voucher Collections');
                                navToProfile();
                                if (onNavigate) onNavigate();
                            }}
                        >
                            Collections
                        </DropdownItem>

                        <DropdownItem
                            className={UserProfileDropdownStyles['dropdown-item']}
                            onClick={() => {
                                dropdownToggle();
                                setProfilePageCurrentTab('Voucher History');
                                navToProfile();
                                if (onNavigate) onNavigate();
                            }}
                        >
                            History
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>

                <DropdownItem
                    className={UserProfileDropdownStyles['dropdown-item']}
                    onClick={() => {
                        setProfilePageCurrentTab('Settings');
                        navToProfile();
                        if (onNavigate) onNavigate();
                    }}
                >
                    Settings
                </DropdownItem>

                <DropdownItem
                    className={UserProfileDropdownStyles['dropdown-item']}
                    onClick={() => {
                        clearAuthToken(); // localStorage
                        authClearAuthToken(); // redux state
                        navToHome();
                        if (onNavigate) onNavigate();
                        toast.success('Successfully logged out!');
                    }}
                >
                    Logout
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

UserProfileDropdown.defaultProps = {
    isMobileMenu: false,
    onNavigate: () => ({}),
};

const mapStateToProps = (state: RootState) => ({
    userInfo: CombinedSelectors.profile.getUserInfo(state.profile),
    userInfoIsLoading: CombinedSelectors.profile.getUserInfoAttempting(state.profile),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setProfilePageCurrentTab: (tab: ProfilePageTabReduxTypes) => dispatch(CombinedActions.profileSetProfilePageCurrentTab(tab)),
    authClearAuthToken: () => dispatch(CombinedActions.authClearAuthToken()),
    getUserInfo: () => dispatch(CombinedActions.profileGetUserInfoAttempt()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileDropdown);
