import React, { useEffect, useState } from 'react';

import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import CTAButton from 'components/CTAButton';
import { useLocation, useParams } from 'react-router-dom';
import NavActions from 'lib/NavActions';
import { AppDispatch, RootState } from 'redux/store';
import CombinedSelectors from 'redux/CombinedSelectors';
import { connect } from 'react-redux';
import CombinedActions from 'redux/CombinedActions';
import { ResetPasswordReduxParams } from 'redux/slices/auth/types';
import ResetPasswordStyles from './_ResetPasswordStyles.module.scss';

interface ResetPasswordProps {
    submitResetPasswordIsLoading: boolean;
    submitResetPasswordError: string;
    submitResetPassword: (param: ResetPasswordReduxParams) => void;
}

const ResetPassword = (props: ResetPasswordProps): JSX.Element => {
    const {
        submitResetPasswordIsLoading,
        submitResetPasswordError,
        submitResetPassword,
    } = props;

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paramEmail = queryParams.get('email');
    const paramOtp = queryParams.get('otp');

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [newPasswordInvalid, setNewPasswordInvalid] = useState('');
    const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState('');

    // ? this is to make sure only when user have both email and otp before resetting password
    useEffect(() => {
        if (paramEmail && paramOtp) {
            setEmail(paramEmail);
            setOtp(paramOtp);
        } else {
            setEmail('');
            setOtp('');
            NavActions.navToForgotPassword();
        }
    }, [paramEmail, paramOtp]);

    useEffect(() => {
        if (newPassword) {
            setNewPasswordInvalid('');
        }
        if (confirmPassword) {
            setConfirmPasswordInvalid('');
        }
    }, [newPassword, confirmPassword]);

    const handleResetClick = () => {
        if (!newPassword) {
            setNewPasswordInvalid('New password cannot be blank');
            return;
        }
        if (!confirmPassword) {
            setConfirmPasswordInvalid('Confirm password cannot be blank');
            return;
        }
        if (newPassword && confirmPassword) {
            if (newPassword !== confirmPassword) {
                setNewPasswordInvalid('Passwords do not match');
                setConfirmPasswordInvalid('Passwords do not match');
                return;
            }
            if (newPassword.length < 6) {
                setNewPasswordInvalid('Password must be at least 6 characters long');
                return;
            }
            setNewPasswordInvalid('');
            setConfirmPasswordInvalid('');
            submitResetPassword({ email, otp, newPassword });
        }
    };
    return (
        <div className={ResetPasswordStyles.container}>
            <h1>
                Reset Password
            </h1>
            <div className={ResetPasswordStyles.subcontainer}>
                <Form>
                    <FormGroup className={ResetPasswordStyles['form-group']}>
                        <Label
                            className={ResetPasswordStyles.label}
                            for='newPassword'
                        >
                            New Password
                        </Label>

                        <Input
                            type='password'
                            name='newPassword'
                            placeholder='enter new password'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.currentTarget.value)}
                            invalid={newPasswordInvalid ? true : undefined}
                        />
                        <FormFeedback
                            invalid={newPasswordInvalid ? 'true' : undefined}
                            className={ResetPasswordStyles['form-feedback']}
                        >
                            {newPasswordInvalid}
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup className={ResetPasswordStyles['form-group']}>
                        <Label
                            className={ResetPasswordStyles.label}
                            for='confirmPassword'
                        >
                            Confirm New Password
                        </Label>

                        <Input
                            type='password'
                            name='confirmPassword'
                            placeholder='repeat new password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                            invalid={confirmPasswordInvalid ? true : undefined}
                        />

                        <FormFeedback
                            invalid={confirmPasswordInvalid ? 'true' : undefined}
                            className={ResetPasswordStyles['form-feedback']}
                        >
                            {confirmPasswordInvalid}
                        </FormFeedback>
                    </FormGroup>
                </Form>
                <div className={ResetPasswordStyles['button-container']}>
                    <CTAButton
                        label='Reset'
                        onClick={handleResetClick}
                        isLoading={submitResetPasswordIsLoading}
                    />
                </div>

                {submitResetPasswordError
                && (
                    <p className={ResetPasswordStyles.error}>
                        {submitResetPasswordError}
                    </p>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    submitResetPasswordIsLoading: CombinedSelectors.auth.getResetPasswordAttempting(state.auth),
    submitResetPasswordError: CombinedSelectors.auth.getResetPasswordError(state.auth),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    submitResetPassword: (param: ResetPasswordReduxParams) => dispatch(CombinedActions.authResetPasswordAttempt(param)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
