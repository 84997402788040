import React from 'react';

import CustomAccordion from 'components/CustomAccordion';
import { ContainerConstants } from 'lib/Constants';

import FAQSectionStyles from './_FAQSectionStyles.module.scss';

const FAQSection = (): JSX.Element => {
    const {
        homePage: {
            faqContainerId,
            faqCopy,
        },
    } = ContainerConstants;

    return (
        <div
            id={faqContainerId}
            className={FAQSectionStyles.container}
        >
            <h2>
                FAQ
            </h2>

            <CustomAccordion
                data={faqCopy}
            />
        </div>
    );
};

export default FAQSection;
