import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { AppDispatch } from 'redux/store';
import { AuthPageTabReduxTypes } from 'redux/slices/auth/types';
import CombinedActions from 'redux/CombinedActions';

import CTAButton from 'components/CTAButton';
import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';
import { ContainerConstants } from 'lib/Constants';
import HomePageBannerImage from '../../../assets/images/home-page-banner-v4.webp';
import HomePageBannerMobileImage from '../../../assets/images/home-page-banner-mobile-v4.webp';
import { ReactComponent as HomePageBannerHeaderIcon } from '../../../assets/icons/home-page-banner-header.svg';

import HomePageBannerStyles from './_HomePageBannerStyles.module.scss';

interface HomePageBannerProps {
    setAuthPageCurrentTab: (tab: AuthPageTabReduxTypes) => void;
}

const HomePageBanner = (props: HomePageBannerProps): JSX.Element => {
    const {
        setAuthPageCurrentTab,
    } = props;

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWindowWidth(window.innerWidth);
        });

        return () => {
            window.removeEventListener('resize', () => {
                setWindowWidth(window.innerWidth);
            });
        };
    }, []);

    const isAuthenticated = Utils.Auth.getAuthToken();
    const { navToProducts, navToLogin, navToProfile } = NavActions;

    const { homePage: {
        homePageBannerCTAText,
        homePageBannerSubHeader,
    } } = ContainerConstants;

    const renderBannerDescription = () => {
        const productsButtonText = 'products';
        const klookText = 'Klook';

        return (
            <p className={HomePageBannerStyles['banner-cta-paragraph-container']}>
                {homePageBannerCTAText.substring(0, homePageBannerCTAText.indexOf(productsButtonText))}

                <button
                    type='button'
                    onClick={navToProducts}
                >
                    {productsButtonText}
                </button>

                {homePageBannerCTAText.substring(homePageBannerCTAText.indexOf(productsButtonText) + productsButtonText.length, homePageBannerCTAText.indexOf(klookText))}

                <span className={HomePageBannerStyles['banner-klook-text']}>
                    {klookText}
                </span>

                {homePageBannerCTAText.substring(homePageBannerCTAText.indexOf(klookText) + klookText.length)}
            </p>
        );
    };

    return (
        <div
            className={HomePageBannerStyles.container}
        >
            <img
                src={windowWidth < 769 ? HomePageBannerMobileImage : HomePageBannerImage}
                alt='buy and redeem program banner'
                width='1920px'
                height='800px'
            />

            <HomePageBannerHeaderIcon />

            <p className={HomePageBannerStyles['banner-subheader']}>
                {homePageBannerSubHeader}
            </p>

            {renderBannerDescription()}

            <CTAButton
                onClick={() => {
                    if (isAuthenticated) {
                        navToProfile();
                        return;
                    }

                    setAuthPageCurrentTab('Login');
                    navToLogin();
                }}
                label='Redeem Now'
                className={HomePageBannerStyles['redeem-button']}
            />
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setAuthPageCurrentTab: (tab: AuthPageTabReduxTypes) => dispatch(CombinedActions.authSetAuthPageCurrentTab(tab)),
});

export default connect(null, mapDispatchToProps)(HomePageBanner);
