import { GetVouchersAPIResponse, GetUserInfoAPIResponse } from 'api/ProfileBase';
import { ProfileState } from '.';
import { ProfilePageTabReduxTypes, VoucherDetailsReduxState } from './types';

const getVoucherCollectionAttempting = (state: ProfileState): boolean => state.loadings.getVoucherCollection || false;
const getVoucherCollectionError = (state: ProfileState): string => state.errors.getVoucherCollection || '';
const getVoucherCollection = (state: ProfileState): GetVouchersAPIResponse => state.voucherCollection || {};

const getVoucherHistoryAttempting = (state: ProfileState): boolean => state.loadings.getVoucherHistory || false;
const getVoucherHistoryError = (state: ProfileState): string => state.errors.getVoucherHistory || '';
const getVoucherHistory = (state: ProfileState): GetVouchersAPIResponse => state.voucherHistory || {};

const getProfilePageCurrentTab = (state: ProfileState): ProfilePageTabReduxTypes => state.profilePageCurrentTab || 'Upload Receipt';

const getUserInfoAttempting = (state: ProfileState): boolean => state.loadings.getUserInfo || false;
const getUserInfoError = (state: ProfileState): string => state.errors.getUserInfo || '';
const getUserInfo = (state: ProfileState): GetUserInfoAPIResponse => state.userInfo || {};

const updateUserInfoAttempting = (state: ProfileState): boolean => state.loadings.updateUserInfo || false;
const updateUserInfoError = (state: ProfileState): string => state.errors.updateUserInfo || '';

const changePasswordAttempting = (state: ProfileState): boolean => state.loadings.changePassword || false;
const changePasswordError = (state: ProfileState): string => state.errors.changePassword || '';

const getSubmitReceiptAttempting = (state: ProfileState): boolean => state.loadings.submitReceipt || false;
const getSubmitReceiptError = (state: ProfileState): string => state.errors.submitReceipt || '';

const getUpdateUserInfoAttempting = (state: ProfileState): boolean => state.loadings.updateUserInfo || false;
const getUpdateUserInfoError = (state: ProfileState): string => state.errors.updateUserInfo || '';

const getVoucherDetails = (state: ProfileState): VoucherDetailsReduxState => state.voucherDetails || {};

const getRedeemVoucherAttempting = (state: ProfileState): boolean => state.loadings.redeemVoucher || false;
const getRedeemVoucherError = (state: ProfileState): string => state.errors.redeemVoucher || '';

const getChangePasswordStatus = (state: ProfileState): boolean => state.changePasswordStatus || false;

export default {
    getVoucherCollectionAttempting,
    getVoucherCollectionError,
    getVoucherCollection,

    getVoucherHistoryAttempting,
    getVoucherHistoryError,
    getVoucherHistory,

    getProfilePageCurrentTab,

    getUserInfoAttempting,
    getUserInfoError,
    getUserInfo,

    updateUserInfoAttempting,
    updateUserInfoError,

    changePasswordAttempting,
    changePasswordError,

    getSubmitReceiptAttempting,
    getSubmitReceiptError,

    getUpdateUserInfoAttempting,
    getUpdateUserInfoError,

    getVoucherDetails,

    getRedeemVoucherAttempting,
    getRedeemVoucherError,

    getChangePasswordStatus,
};
