import React, { useState } from 'react';
import { connect } from 'react-redux';

import CombinedActions from 'redux/CombinedActions';
import CombinedSelectors from 'redux/CombinedSelectors';
import { ProfilePageTabReduxTypes } from 'redux/slices/profile/types';
import { AppDispatch, RootState } from 'redux/store';
import { ReactComponent as ChevronUpLogo } from '../../../assets/icons/chevron-up.svg';
import { ReactComponent as UploadLogo } from '../../../assets/icons/upload.svg';
import { ReactComponent as VoucherLogo } from '../../../assets/icons/tags.svg';
import { ReactComponent as CogLogo } from '../../../assets/icons/cog.svg';

import TabButtonContainerStyles from './_TabButtonContainerStyles.module.scss';

interface TabButtonContainerProps {
    currentTab: ProfilePageTabReduxTypes;
    setCurrentTab: (tab: ProfilePageTabReduxTypes) => void;
}

const TabButtonContainer = (props: TabButtonContainerProps): JSX.Element => {
    const {
        currentTab,
        setCurrentTab,
    } = props;

    const [voucherIsExpanded, setVoucherIsExpanded] = useState(false);

    return (
        <div className={TabButtonContainerStyles.container}>
            <button
                type='button'
                onClick={() => setCurrentTab('Upload Receipt')}
                className={currentTab === 'Upload Receipt' ? TabButtonContainerStyles['is-active'] : ''}
            >
                <UploadLogo />

                Upload Receipt
            </button>

            <button
                type='button'
                onClick={() => setVoucherIsExpanded(!voucherIsExpanded)}
                className={`${TabButtonContainerStyles['voucher-button']} ${(currentTab === 'Voucher Collections' || currentTab === 'Voucher History') ? TabButtonContainerStyles['is-active'] : ''}`}
            >
                <VoucherLogo />

                <div>
                    Voucher(s)

                    <ChevronUpLogo className={voucherIsExpanded ? TabButtonContainerStyles['is-expanded'] : ''} />
                </div>
            </button>

            {voucherIsExpanded && (
                <>
                    <button
                        type='button'
                        onClick={() => setCurrentTab('Voucher Collections')}
                        className={`${TabButtonContainerStyles['embedded-button']} ${currentTab === 'Voucher Collections' ? TabButtonContainerStyles['hidden-button-is-active'] : ''}`}
                    >
                        Collections
                    </button>

                    <button
                        type='button'
                        onClick={() => setCurrentTab('Voucher History')}
                        className={`${TabButtonContainerStyles['embedded-button']} ${currentTab === 'Voucher History' ? TabButtonContainerStyles['hidden-button-is-active'] : ''}`}
                    >
                        History
                    </button>
                </>
            )}

            <button
                type='button'
                onClick={() => setCurrentTab('Settings')}
                className={currentTab === 'Settings' ? TabButtonContainerStyles['is-active'] : ''}
            >
                <CogLogo />

                Settings
            </button>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentTab: CombinedSelectors.profile.getProfilePageCurrentTab(state.profile),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setCurrentTab: (tab: ProfilePageTabReduxTypes) => dispatch(CombinedActions.profileSetProfilePageCurrentTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TabButtonContainer);
