import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Utils from 'lib/Utils';
import HomePageBanner from './HomePageBanner';
import HomePageBody from './HomePageBody';

const Home = (): JSX.Element => {
    const location = useLocation();
    const { hash } = location;

    useEffect(() => {
        if (hash) {
            const hashContainer = document.querySelector(hash);

            if (hashContainer) {
                const hashContainerMetaData = hashContainer.getBoundingClientRect();
                const navBarHeight = Utils.Styles.getNavBarHeight();
                window.scrollTo(0, hashContainerMetaData.y + window.scrollY - navBarHeight);
            }
        }
    }, [location]);

    return (
        <>
            <HomePageBanner />

            <HomePageBody />
        </>
    );
};

export default Home;
