import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { SagaWatcherReturnType } from 'sagas/types';
import ProfileGateway from 'api/Profile';
import CombinedActions from 'redux/CombinedActions';
import { GatewayResponseStatus } from 'api/types/types';
import CombinedSelectors from 'redux/CombinedSelectors';
import { GetVoucherCollectionReduxParams } from 'redux/slices/profile/types';

import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';

export default function* watchGetVoucherCollection(api: ProfileGateway): SagaWatcherReturnType {
    yield takeEvery('profile/profileGetVoucherCollectionAttempt', handleGetVoucherCollection, api);
}

function* handleGetVoucherCollection(api: ProfileGateway, data: PayloadAction<GetVoucherCollectionReduxParams>) {
    const {
        status,
        index,
    } = data.payload;

    const { Auth: { getAuthToken } } = Utils;

    const { navResetToLogin } = NavActions;

    let authToken = yield* select(CombinedSelectors.auth.getAuthToken);

    if (!authToken) authToken = getAuthToken() || '';

    if (!authToken) {
        yield put(CombinedActions.profileGetVoucherCollectionFailure('Something went wrong. Please try again later'));
        navResetToLogin();
        return;
    }

    if (status < 0 || index < 0) {
        yield put(CombinedActions.profileGetVoucherCollectionFailure('Something went wrong. Please try again later'));
        return;
    }

    const response = yield* call([api, api.getVouchers], { ...data.payload, authToken });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(CombinedActions.profileGetVoucherCollectionFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            yield put(CombinedActions.profileGetVoucherCollectionSuccess(response.data));
            return;
        }

        yield put(CombinedActions.profileGetVoucherCollectionFailure('Something went wrong. Please try again later'));
    }
}
