import React from 'react';

import ImageAndDescriptionCardStyles from './_ImageAndDescriptionCardStyles.module.scss';

interface ImageAndDescriptionCardProps {
    imageSrc: string;
    imageAlt: string;
    description: string | JSX.Element;
}

const ImageAndDescriptionCard = (props: ImageAndDescriptionCardProps): JSX.Element => {
    const {
        imageSrc,
        imageAlt,
        description,
    } = props;

    return (
        <div className={ImageAndDescriptionCardStyles.container}>
            <img
                src={imageSrc}
                alt={imageAlt}
                width='100%'
            />

            {typeof description === 'string'
                ? <p>{description}</p>
                : description}
        </div>
    );
};

export default ImageAndDescriptionCard;
