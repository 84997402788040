import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Paginator from 'components/Paginator';
import { AppDispatch, RootState } from 'redux/store';
import CombinedSelectors from 'redux/CombinedSelectors';
import CombinedActions from 'redux/CombinedActions';
import { GetVouchersAPIResponse } from 'api/ProfileBase';
import { GetVoucherCollectionReduxParams, VoucherDetailsReduxState, VoucherStatusType } from 'redux/slices/profile/types';
import VoucherCard from 'components/VoucherCard';

import VoucherCollectionsStyles from './_VoucherCollectionsStyles.module.scss';

interface VoucherCollectionsProps {
    voucherCollection: GetVouchersAPIResponse;
    getVoucherCollectionIsLoading: boolean;
    setIndex: (index: number) => void;
    getVoucherCollection: (params: GetVoucherCollectionReduxParams) => void;
    setVoucherDetails: (params: VoucherDetailsReduxState) => void;
}

const VoucherCollections = (props: VoucherCollectionsProps): JSX.Element => {
    const {
        voucherCollection,
        getVoucherCollectionIsLoading,
        setIndex,
        getVoucherCollection,
        setVoucherDetails,
    } = props;

    const {
        index,
        maxIndex,
        data,
    } = voucherCollection;

    useEffect(() => {
        getVoucherCollection({
            status: VoucherStatusType.Active,
            index,
        });
    }, [index]);

    const renderVoucherContainer = () => {
        return (
            <div className={VoucherCollectionsStyles['voucher-container']}>
                {getVoucherCollectionIsLoading
                    ? (
                        <div>
                            <div className={VoucherCollectionsStyles['loading-skeleton']} />
                            <div className={VoucherCollectionsStyles['loading-skeleton']} />
                            <div className={VoucherCollectionsStyles['loading-skeleton']} />
                        </div>
                    )
                    : renderVoucherCollection()}
            </div>
        );
    };

    const renderVoucherCollection = () => {
        return data.map(voucher => {
            const {
                externalTitle,
                bannerImage,
                description,
                expiryDate,
                tnc,
                redeemed,
                redeemedDate,
                voucherCodeid,
                thumbnailImage,
                code,
            } = voucher;

            return (
                <VoucherCard
                    expiryDate={expiryDate}
                    externalTitle={externalTitle}
                    redeemed={redeemed}
                    redeemedDate={redeemedDate}
                    thumbnailImage={thumbnailImage}
                    key={voucherCodeid}
                    onClick={() => setVoucherDetails({
                        externalTitle,
                        bannerImage,
                        description,
                        expiryDate,
                        tnc,
                        redeemed,
                        redeemedDate,
                        voucherCodeid,
                        code,
                    })}
                />
            );
        });
    };

    const renderFooter = () => {
        if (data.length > 0) {
            return (
                <Paginator
                    index={index}
                    maxIndex={maxIndex}
                    setIndex={setIndex}
                />
            );
        }

        return (
            <div className={VoucherCollectionsStyles['empty-list-message']}>
                {getVoucherCollectionIsLoading ? '' : 'No vouchers found'}
            </div>
        );
    };

    return (
        <div className={VoucherCollectionsStyles.container}>
            <h2>
                Voucher Collections
            </h2>

            {renderVoucherContainer()}

            {renderFooter()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    voucherCollection: CombinedSelectors.profile.getVoucherCollection(state.profile),
    getVoucherCollectionIsLoading: CombinedSelectors.profile.getVoucherCollectionAttempting(state.profile),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIndex: (index: number) => dispatch(CombinedActions.profileSetVoucherCollectionIndex(index)),
    getVoucherCollection: (params: GetVoucherCollectionReduxParams) => dispatch(CombinedActions.profileGetVoucherCollectionAttempt(params)),
    setVoucherDetails: (params: VoucherDetailsReduxState) => dispatch(CombinedActions.profileSetVoucherDetails(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherCollections);
