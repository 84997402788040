import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import CombinedActions from 'redux/CombinedActions';
import NavActions from 'lib/NavActions';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ForgotPasswordReduxParams } from 'redux/slices/auth/types';

export default function* watchForgotPassword(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authForgotPasswordAttempt', handleForgotPassword, api);
}

function* handleForgotPassword(api: AuthGateway, data: PayloadAction<ForgotPasswordReduxParams>) {
    const { email } = data.payload;

    const { navToVerifyOtp } = NavActions;

    if (!email) {
        yield put(CombinedActions.authForgotPasswordFailure('Please enter your email'));
        return;
    }

    const response = yield* call([api, api.forgotPassword], email);

    if (response.status === GatewayResponseStatus.Error) {
        yield put(CombinedActions.authForgotPasswordFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(CombinedActions.authForgotPasswordSuccess());
        toast.success('Verification email sent!');

        navToVerifyOtp(email, '');
    }
}
