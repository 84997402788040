import { put, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import CombinedActions from 'redux/CombinedActions';
import CombinedSelectors from 'redux/CombinedSelectors';

import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';

export default function* watchSubmitReceipt(): SagaWatcherReturnType {
    yield takeEvery('profile/profileSubmitReceiptAttempt', handleSubmitReceipt);
}

function* handleSubmitReceipt() {
    const { Auth: { getAuthToken } } = Utils;

    const { navResetToLogin } = NavActions;

    let authToken = yield* select(CombinedSelectors.auth.getAuthToken);

    if (!authToken) authToken = getAuthToken() || '';

    if (!authToken) {
        yield put(CombinedActions.profileSubmitReceiptFailure('Something went wrong. Please try again later'));
        navResetToLogin();
    }
}
