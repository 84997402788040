import React, { useEffect } from 'react';

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import PaginatorStyles from './_PaginatorStyles.module.scss';

interface PaginatorProps {
    index: number;
    maxIndex: number;
    setIndex: (index: number) => void;
}

const Paginator = (props: PaginatorProps): JSX.Element => {
    const {
        index,
        maxIndex,
        setIndex,
    } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [index]);

    const renderDropdownItems = () => {
        const dropdownItems = [];

        for (let i = 0; i < maxIndex; i += 1) {
            dropdownItems.push(
                <DropdownItem
                    onClick={() => setIndex(i + 1)}
                    key={i + 1}
                >
                    {i + 1}
                </DropdownItem>,
            );
        }

        return dropdownItems;
    };

    return (
        <div className={PaginatorStyles.container}>
            Page

            <UncontrolledDropdown>
                <DropdownToggle
                    disabled={index < 1}
                    caret
                >
                    {index || ''}
                </DropdownToggle>

                <DropdownMenu>
                    {renderDropdownItems()}
                </DropdownMenu>
            </UncontrolledDropdown>

            /

            {maxIndex}

            <button
                type='button'
                onClick={() => index > 1 && setIndex(index - 1)}
            >
                PREV
            </button>

            <button
                type='button'
                onClick={() => index < maxIndex && setIndex(index + 1)}
            >
                NEXT
            </button>
        </div>
    );
};

export default Paginator;
