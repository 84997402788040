import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetUserInfoAPIResponse, GetVouchersAPIResponse } from 'api/ProfileBase';

import {
    ChangePasswordReduxParams,
    GetVoucherCollectionReduxParams,
    GetVoucherHistoryReduxParams,
    ProfilePageTabReduxTypes,
    ProfileReduxState,
    RedeemVoucherReduxParams,
    UpdateUserInfoReduxParams,
    VoucherDetailsReduxState,
} from './types';

const initialState: ProfileReduxState = {
    loadings: {
        getVoucherCollection: false,
        getVoucherHistory: true,
        getUserInfo: false,
        submitReceipt: false,
        updateUserInfo: false,
        redeemVoucher: false,
        changePassword: false,
    },
    profilePageCurrentTab: 'Upload Receipt',
    userInfo: {
        userId: '',
        email: '',
        role: -1,
        fullName: '',
        phoneNumber: '',
    },
    voucherCollection: {
        index: 1,
        maxIndex: 0,
        data: [],
    },
    voucherHistory: {
        index: 1,
        maxIndex: 0,
        data: [],
    },
    voucherDetails: {
        externalTitle: '',
        bannerImage: '',
        description: '',
        expiryDate: '',
        tnc: '',
        redeemed: null,
        redeemedDate: '',
        voucherCodeid: '',
        code: '',
    },
    changePasswordStatus: false,
    errors: {
        getVoucherCollection: '',
        getVoucherHistory: '',
        getUserInfo: '',
        submitReceipt: '',
        updateUserInfo: '',
        redeemVoucher: '',
        changePassword: '',
    },
};

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        profileGetVoucherCollectionAttempt: (state, _action: PayloadAction<GetVoucherCollectionReduxParams>) => {
            state.loadings.getVoucherCollection = true;
            state.errors.getVoucherCollection = '';
        },
        profileGetVoucherCollectionSuccess: (state, action: PayloadAction<GetVouchersAPIResponse>) => {
            state.loadings.getVoucherCollection = false;
            state.voucherCollection = action.payload;
        },
        profileGetVoucherCollectionFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.getVoucherCollection = false;
            if (action.payload) state.errors.getVoucherCollection = action.payload;
        },
        profileSetProfilePageCurrentTab: (state, action: PayloadAction<ProfilePageTabReduxTypes>) => {
            state.profilePageCurrentTab = action.payload;
        },
        profileGetVoucherHistoryAttempt: (state, _action: PayloadAction<GetVoucherHistoryReduxParams>) => {
            state.loadings.getVoucherHistory = true;
            state.errors.getVoucherHistory = '';
        },
        profileGetVoucherHistorySuccess: (state, action: PayloadAction<GetVouchersAPIResponse>) => {
            state.loadings.getVoucherHistory = false;
            state.voucherHistory = action.payload;
        },
        profileGetVoucherHistoryFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.getVoucherHistory = false;
            if (action.payload) state.errors.getVoucherHistory = action.payload;
        },
        profileGetUserInfoAttempt: (state) => {
            state.loadings.getUserInfo = true;
            state.errors.getUserInfo = '';
        },
        profileGetUserInfoSuccess: (state, action: PayloadAction<GetUserInfoAPIResponse>) => {
            state.loadings.getUserInfo = false;
            state.userInfo = action.payload;
        },
        profileGetUserInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.getUserInfo = false;
            if (action.payload) state.errors.getUserInfo = action.payload;
        },
        profileSetUserFullName: (state, action: PayloadAction<string>) => {
            state.userInfo.fullName = action.payload;
        },
        profileUpdateUserInfoAttempt: (state, _action: PayloadAction<UpdateUserInfoReduxParams>) => {
            state.loadings.updateUserInfo = true;
            state.errors.updateUserInfo = '';
        },
        profileUpdateUserInfoSuccess: (state) => {
            state.loadings.updateUserInfo = false;
        },
        profileUpdateUserInfoFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.updateUserInfo = false;
            if (action.payload) state.errors.updateUserInfo = action.payload;
        },
        profileSubmitReceiptAttempt: (state) => {
            state.loadings.submitReceipt = true;
            state.errors.submitReceipt = '';
        },
        profileSubmitReceiptSuccess: (state) => {
            state.loadings.submitReceipt = false;
        },
        profileSubmitReceiptFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.submitReceipt = false;
            if (action.payload) state.errors.submitReceipt = action.payload;
        },
        profileChangePasswordAttempt: (state, _action: PayloadAction<ChangePasswordReduxParams>) => {
            state.loadings.changePassword = true;
            state.errors.changePassword = '';
        },
        profileChangePasswordSuccess: (state) => {
            state.loadings.changePassword = false;
            state.errors.changePassword = '';
            state.changePasswordStatus = true;
        },
        profileChangePasswordFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.changePassword = false;
            if (action.payload) state.errors.changePassword = action.payload;
        },
        profileSetVoucherCollectionIndex: (state, action: PayloadAction<number>) => {
            state.voucherCollection.index = action.payload;
        },
        profileSetVoucherHistoryIndex: (state, action: PayloadAction<number>) => {
            state.voucherHistory.index = action.payload;
        },
        profileSetVoucherDetails: (state, action: PayloadAction<VoucherDetailsReduxState>) => {
            state.voucherDetails = action.payload;
        },
        profileResetVoucherDetails: (state) => {
            state.voucherDetails = initialState.voucherDetails;
        },
        profileResetSettingsErrors: (state) => {
            state.errors = initialState.errors;
        },
        profileRedeemVoucherAttempt: (state, _action: PayloadAction<RedeemVoucherReduxParams>) => {
            state.loadings.redeemVoucher = true;
            state.errors.redeemVoucher = '';
        },
        profileRedeemVoucherSuccess: (state) => {
            state.loadings.redeemVoucher = false;
        },
        profileRedeemVoucherFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.redeemVoucher = false;
            if (action.payload) state.errors.redeemVoucher = action.payload;
        },
        profileResetRedeemVoucherReduxState: (state) => {
            state.loadings.redeemVoucher = initialState.loadings.redeemVoucher;
            state.errors.redeemVoucher = initialState.errors.redeemVoucher;
        },
        profileSetChangePasswordStatus: (state, action: PayloadAction<boolean>) => {
            state.changePasswordStatus = action.payload;
        },
    },
});

export type ProfileState = typeof initialState;

export default {
    actions: profileSlice.actions,
    reducers: profileSlice.reducer,
};
