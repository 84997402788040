import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { IProfileGateway, GetVouchersAPIParams, GetVouchersAPIResponse, GetUserInfoAPIParams, GetUserInfoAPIResponse, UpdateUserInfoAPIParams, ChangePasswordAPIParams, RedeemVoucherAPIParams } from './ProfileBase';

import Utils from './types/Utils';

const { getHeaders } = Utils;

export default class ProfileGateway extends IProfileGateway {
    async getVouchers(params: GetVouchersAPIParams): GatewayResponse<GetVouchersAPIResponse | null> {
        const { authToken, ...restOfGetVouchersParams } = params;
        const response: ApiResponse<GetVouchersAPIResponse> = await this.api.get('/customer/voucher/codes', restOfGetVouchersParams, getHeaders(authToken));
        return this.process(response);
    }

    async getUserInfo(params: GetUserInfoAPIParams): GatewayResponse<GetUserInfoAPIResponse | null> {
        const { authToken } = params;
        const response: ApiResponse<GetUserInfoAPIResponse> = await this.api.get('/customer/userInfo', {}, getHeaders(authToken));
        return this.process(response);
    }

    async redeemVoucher(params: RedeemVoucherAPIParams): GatewayResponse<null> {
        const { authToken, ...restOfRedeemVoucherParams } = params;
        const response: ApiResponse<null> = await this.api.put('/customer/voucher/redeem', restOfRedeemVoucherParams, getHeaders(authToken));
        return this.process(response);
    }

    async updateUserInfo(params: UpdateUserInfoAPIParams): GatewayResponse<string | null> {
        const { authToken, ...restOfUserInfoParams } = params;
        const response: ApiResponse<string | null> = await this.api.put('/customer/userInfo', restOfUserInfoParams, getHeaders(authToken));
        return this.process(response);
    }

    async changePassword(params: ChangePasswordAPIParams): GatewayResponse<string | null> {
        const { authToken, ...restOfChangePasswordParams } = params;
        const response: ApiResponse<string | null> = await this.api.put('/customer/changePassword', restOfChangePasswordParams, getHeaders(authToken));
        return this.process(response);
    }
}
