import React, { useMemo, useRef, useState } from 'react';
import {
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
} from 'reactstrap';
import { connect } from 'react-redux';
import { mixed, number, object, string } from 'yup';
import dayjs from 'dayjs';

import CTAButton from 'components/CTAButton';
import ReactDatePicker from 'components/DatePicker';
import { AppDispatch, RootState } from 'redux/store';
import CombinedSelectors from 'redux/CombinedSelectors';
import CombinedActions from 'redux/CombinedActions';
import Utils from 'lib/Utils';
import config from 'config';
import { ContainerConstants } from 'lib/Constants';
import { ProfilePageTabReduxTypes } from 'redux/slices/profile/types';
import OutletList from 'lib/LOVs';

import UploadReceiptStyles from './_UploadReceiptTabStyles.module.scss';

interface UploadReceiptTabProps {
    submitIsLoading: boolean;
    submitError: string;
    authToken: string;
    submitReceipt: () => void;
    submitReceiptSuccess: () => void;
    submitReceiptFailure: (error: string) => void;
    setCurrentTab: (tab: ProfilePageTabReduxTypes) => void;
}

const UploadReceiptTab = (props: UploadReceiptTabProps): JSX.Element => {
    const {
        submitIsLoading,
        submitError,
        authToken,
        submitReceipt,
        submitReceiptSuccess,
        submitReceiptFailure,
        setCurrentTab,
    } = props;

    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

    const [outlet, setOutlet] = useState('');
    const [item, setItem] = useState('');
    const [purchaseAmount, setPurchaseAmount] = useState('');
    const [date, setDate] = useState<Date | null>(new Date());
    const [receipt, setReceipt] = useState<File | null>(null);

    const [outletInvalid, setOutletInvalid] = useState(false);
    const [itemInvalid, setItemInvalid] = useState(false);
    const [purchaseAmountInvalid, setPurchaseAmountInvalid] = useState(false);
    const [dateInvalid, setDateInvalid] = useState(false);
    const [receiptInvalid, setReceiptInvalid] = useState(false);

    const [submissionSuccessful, setSubmissionSuccessful] = useState(false);

    const [outletFilter, setOutletFilter] = useState('');

    const cachedGetMassagedOutletList = useMemo((): {
        ECP: string;
        Address: string[];
    }[] => {
        const filteredOutletList = outletFilter ? OutletList.map(outletItem => {
            return outletItem.ECP.toLowerCase().indexOf(outletFilter.toLowerCase()) >= 0 ? outletItem : null;
        }) : OutletList;

        const massagedOutletList: {
            ECP: string;
            Address: string[];
        }[] = [];

        filteredOutletList.filter(filteredItem => filteredItem).map(filteredItem => {
            const indexOfRepeatedECP = massagedOutletList?.map((massagedItem: {
                ECP: string;
                Address: string[];
            }) => massagedItem?.ECP).indexOf(filteredItem?.ECP || '');

            if (indexOfRepeatedECP >= 0) {
                if (filteredItem?.ECP) {
                    massagedOutletList[indexOfRepeatedECP] = {
                        ECP: massagedOutletList[indexOfRepeatedECP].ECP,
                        Address: [...massagedOutletList[indexOfRepeatedECP].Address, filteredItem?.Address],
                    };
                }
                return null;
            }

            return massagedOutletList.push({
                ECP: filteredItem?.ECP || '',
                Address: [filteredItem?.Address || ''],
            });
        });

        return massagedOutletList;
    }, [outletFilter]);

    const { Auth: { getAuthToken } } = Utils;

    const { profilePage: {
        submissionCompleteHeader,
        submissionCompleteBody,
    } } = ContainerConstants;

    const FileInputRef = useRef<HTMLInputElement>(null);

    const loginFormSchema = object({
        outlet: string().required(),
        item: string().required(),
        purchaseAmount: number().min(1).required(),
        date: string().required(),
        receipt: mixed().required(),
    });

    const handlePurchaseAmountOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (e.currentTarget.value.charAt(e.currentTarget.value.length - 1) === '.') setPurchaseAmount(purchaseAmount.concat('00'));
        if (e.currentTarget.value.charAt(0) === '.') setPurchaseAmount(`0${purchaseAmount}`);
    };

    const renderDropdownItems = () => {
        return cachedGetMassagedOutletList.filter(massagedOutlet => massagedOutlet !== null).map((massagedOutlet, index) => {
            const {
                ECP,
                Address,
            } = massagedOutlet!;

            return (
                <React.Fragment
                    key={ECP?.concat(index.toString())}
                >
                    <div
                        className={UploadReceiptStyles['dropdown-item-header']}
                    >
                        {ECP?.toUpperCase()}
                    </div>

                    {Address.map((add: string) => {
                        return (
                            <button
                                type='button'
                                className={UploadReceiptStyles['dropdown-item']}
                                onMouseDown={() => {
                                    setOutletFilter('');
                                    setOutlet(add);
                                    setDropdownIsOpen(false);
                                    if (outletInvalid) setOutletInvalid(false);
                                }}
                                key={ECP?.concat(add).concat(index.toString())}
                                tabIndex={0}
                            >
                                {add}
                            </button>
                        );
                    })}
                </React.Fragment>
            );
        });
    };

    const handleSubmitClick = async () => {
        const validateOutletPromise = new Promise((resolve, reject) => {
            try {
                loginFormSchema.validateAt('outlet', { outlet })
                    .then(
                        (value) => {
                            setOutletInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setOutletInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        const validateItemPromise = new Promise((resolve, reject) => {
            try {
                loginFormSchema.validateAt('item', { item })
                    .then(
                        (value) => {
                            setItemInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setItemInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        const validateAmountPromise = new Promise((resolve, reject) => {
            try {
                loginFormSchema.validateAt('purchaseAmount', { purchaseAmount })
                    .then(
                        (value) => {
                            setPurchaseAmountInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setPurchaseAmountInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        const validateDatePromise = new Promise((resolve, reject) => {
            try {
                loginFormSchema.validateAt('date', { date })
                    .then(
                        (value) => {
                            setDateInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setDateInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        const validateReceiptPromise = new Promise((resolve, reject) => {
            try {
                loginFormSchema.validateAt('receipt', { receipt })
                    .then(
                        (value) => {
                            setReceiptInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setReceiptInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        await Promise.allSettled([
            validateOutletPromise,
            validateItemPromise,
            validateAmountPromise,
            validateDatePromise,
            validateReceiptPromise,
        ])
            .then((results) => {
                const fieldInvalid = results.find((result) => result.status === 'rejected');

                if (!receipt || fieldInvalid) return;

                const token = authToken || getAuthToken() || '';

                const bodyFormData = new FormData();

                bodyFormData.append('outlet', outlet);
                bodyFormData.append('purchasedItem', item);
                bodyFormData.append('amount', purchaseAmount);
                bodyFormData.append('purchasedDate', dayjs(date).format('YYYY-MM-DD') || '');
                bodyFormData.append('receiptImage', receipt);

                submitReceipt();

                fetch(
                    `${config.baseUrl}/customer/submission`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        body: bodyFormData,
                    },
                ).then((response) => {
                    if (response.ok) {
                        setSubmissionSuccessful(true);
                        submitReceiptSuccess();
                        setOutlet('');
                        setItem('');
                        setPurchaseAmount('');
                        setDate(null);
                        setReceipt(null);
                        return;
                    }

                    submitReceiptFailure(response.statusText || 'Something went wrong. Please try again later');
                }).catch((error) => {
                    submitReceiptFailure(error.message || 'Something went wrong. Please try again later');
                });
            });
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.name === 'amount') {
            const amountRegex = /^\d*\.?\d*$/g;

            if (e.currentTarget.value.match(amountRegex)) {
                if (purchaseAmountInvalid) setPurchaseAmountInvalid(false);
                setPurchaseAmount(e.currentTarget.value);
            }
        }

        if (e.currentTarget.name === 'receipt') {
            if (e.currentTarget.files?.length) {
                if (e.currentTarget.files[0].size > 5000000) {
                    setReceiptInvalid(true);
                    return;
                }

                setReceiptInvalid(false);
                setReceipt(e.currentTarget?.files[0]);
            }
        }
    };

    const handleOutletSearchInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOutletFilter(e.currentTarget.value);
        if (outletInvalid) setOutletInvalid(false);
        if (!dropdownIsOpen) setDropdownIsOpen(true);
    };

    const handleOutletSearchInputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Tab') e.preventDefault();
        if (e.key === 'Enter') {
            if (!dropdownIsOpen) setDropdownIsOpen(true);
        }

        setOutlet('');
        setOutletFilter(e.currentTarget.value);
    };

    const renderUploadReceiptContent = () => {
        return (
            <div style={{ display: submissionSuccessful ? 'none' : '' }}>
                <Form className={UploadReceiptStyles['form-table']}>
                    <FormGroup className={UploadReceiptStyles['form-group']}>
                        <Label
                            className={UploadReceiptStyles.label}
                        >
                            Purchase Outlet*
                        </Label>

                        <div className={`${UploadReceiptStyles['outlet-search-container']} ${outletInvalid ? 'is-invalid' : ''}`}>
                            <input
                                onChange={(e) => handleOutletSearchInputOnChange(e)}
                                onKeyDown={(e) => handleOutletSearchInputOnKeyDown(e)}
                                onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
                                placeholder='Search outlet location'
                                className={`${dropdownIsOpen ? UploadReceiptStyles['is-toggled'] : ''} ${outletInvalid ? 'is-invalid' : ''}`}
                                value={outlet || outletFilter}
                                onBlur={() => setDropdownIsOpen(false)}
                            />

                            <div className={`${UploadReceiptStyles['dropdown-menu']} ${dropdownIsOpen ? UploadReceiptStyles['is-open'] : ''}`}>
                                {renderDropdownItems()}
                            </div>
                        </div>

                        <FormFeedback invalid={outletInvalid ? 'true' : undefined}>
                            Please select an outlet.
                        </FormFeedback>
                    </FormGroup>

                    <FormGroup className={UploadReceiptStyles['form-group']}>
                        <Label
                            className={UploadReceiptStyles.label}
                            for='item'
                        >
                            Purchase Item*
                        </Label>

                        <Input
                            type='text'
                            name='item'
                            id='item'
                            placeholder='enter purchase item*'
                            value={item}
                            onChange={(e) => {
                                setItem(e.currentTarget.value);
                                if (itemInvalid) setItemInvalid(false);
                            }}
                            invalid={itemInvalid || undefined}
                        />

                        <FormFeedback invalid={itemInvalid ? 'true' : undefined}>
                            Please enter purchase item(s).
                        </FormFeedback>
                    </FormGroup>

                    <FormGroup className={UploadReceiptStyles['form-group']}>
                        <Label
                            className={UploadReceiptStyles.label}
                            for='amount'
                        >
                            Purchase Amount*
                        </Label>

                        <div className={`${UploadReceiptStyles['purchase-amount-input-container']} ${purchaseAmountInvalid ? 'is-invalid' : ''}`}>
                            <Input
                                type='text'
                                name='amount'
                                id='amount'
                                maxLength={15}
                                value={`${purchaseAmount || ''}`}
                                onChange={(e) => handleOnChange(e)}
                                onBlur={(e) => handlePurchaseAmountOnBlur(e)}
                                invalid={purchaseAmountInvalid || undefined}
                            />
                        </div>

                        <FormFeedback invalid={purchaseAmountInvalid ? 'true' : undefined}>
                            Please enter purchase amount.
                        </FormFeedback>
                    </FormGroup>

                    <FormGroup className={UploadReceiptStyles['form-group']}>
                        <Label
                            className={UploadReceiptStyles.label}
                            for='date'
                        >
                            Date of Purchase*
                        </Label>

                        {/* this div is for anchoring the formfeedback to this div, without this the feedback wont show */}
                        <div className={`${dateInvalid ? 'is-invalid' : ''}`}>
                            <ReactDatePicker
                                onChange={(data) => {
                                    setDate(data);
                                    if (dateInvalid) setDateInvalid(false);
                                }}
                                selected={date}
                                className={dateInvalid ? 'is-invalid' : ''}
                                name='date'
                                id='date'
                                placeholderText='DD/MM/YYYY'
                                maxDate={new Date()}
                                showIcon
                            />
                        </div>

                        <FormFeedback invalid={dateInvalid ? 'true' : undefined}>
                            Please enter date of purchase.
                        </FormFeedback>
                    </FormGroup>

                    <FormGroup className={UploadReceiptStyles['form-group']}>
                        <Label
                            className={UploadReceiptStyles.label}
                            for='receipt'
                        >
                            Upload Receipt*
                        </Label>

                        <input
                            type='file'
                            name='receipt'
                            id='receipt'
                            accept='image/jpeg,image/png,application/pdf'
                            ref={FileInputRef}
                            onChange={(e) => handleOnChange(e)}
                            className={receiptInvalid ? 'is-invalid' : ''}
                            style={{ display: 'none' }}
                        />

                        <button
                            type='button'
                            onClick={() => FileInputRef.current?.click()}
                            className={`${UploadReceiptStyles['upload-file-button']} ${receiptInvalid ? UploadReceiptStyles['is-invalid'] : ''}`}
                        >
                            {
                                receipt?.size && receipt.size > 0
                                    ? (
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ overflow: 'hidden' }}>{receipt?.name}</div>

                                            {(receipt.type !== 'application/pdf') && (
                                                <img
                                                    src={URL.createObjectURL(receipt)}
                                                    alt='receipt'
                                                    className={UploadReceiptStyles['upload-file-preview']}
                                                />
                                            )}
                                        </div>
                                    )
                                    : 'Choose File'
                            }
                        </button>

                        <FormFeedback invalid={receiptInvalid ? 'true' : undefined}>
                            Please upload receipt photo. (max 5MB)
                        </FormFeedback>
                    </FormGroup>
                </Form>

                <br />

                <CTAButton
                    label='Submit'
                    onClick={handleSubmitClick}
                    isLoading={submitIsLoading}
                    className={UploadReceiptStyles['submit-button']}
                />

                {submitError
                    && (
                        <p className={UploadReceiptStyles.error}>
                            {submitError}
                        </p>
                    )}
            </div>
        );
    };

    const renderSubmissionCompleteBody = () => {
        const voucherCollectionText = 'voucher(s) collection';

        return (
            <p className={UploadReceiptStyles['submission-complete-body']}>
                {submissionCompleteBody.substring(0, submissionCompleteBody.indexOf(voucherCollectionText))}

                <a
                    href='/profile'
                    onClick={(e) => {
                        e.preventDefault();
                        setCurrentTab('Voucher Collections');
                        setSubmissionSuccessful(false);
                    }}
                >
                    {` ${voucherCollectionText} `}
                </a>

                {submissionCompleteBody.substring(submissionCompleteBody.indexOf(voucherCollectionText) + voucherCollectionText.length)}
            </p>
        );
    };

    const renderSubmissionCompleteContent = () => {
        return (
            <div
                style={{ display: !submissionSuccessful ? 'none' : '' }}
                className={UploadReceiptStyles['submission-complete-text-container']}
            >
                <p className={UploadReceiptStyles['submission-complete-header']}>
                    {submissionCompleteHeader}
                </p>

                {renderSubmissionCompleteBody()}

                <CTAButton
                    label='Complete'
                    onClick={() => setSubmissionSuccessful(false)}
                    isLoading={submitIsLoading}
                    className={UploadReceiptStyles['complete-button']}
                />
            </div>
        );
    };

    return (
        <div className={UploadReceiptStyles.container}>
            {renderUploadReceiptContent()}

            {renderSubmissionCompleteContent()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    submitIsLoading: CombinedSelectors.profile.getSubmitReceiptAttempting(state.profile),
    submitError: CombinedSelectors.profile.getSubmitReceiptError(state.profile),
    authToken: CombinedSelectors.auth.getAuthToken(state.auth),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    submitReceipt: () => dispatch(CombinedActions.profileSubmitReceiptAttempt()),
    submitReceiptSuccess: () => dispatch(CombinedActions.profileSubmitReceiptSuccess()),
    submitReceiptFailure: (error: string) => dispatch(CombinedActions.profileSubmitReceiptFailure(error)),
    setCurrentTab: (tab: ProfilePageTabReduxTypes) => dispatch(CombinedActions.profileSetProfilePageCurrentTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadReceiptTab);
