type GetHeadersReturnType = {
    headers: {
        Authorization: string;
    }
    timeout?: number;
}

const getHeaders = (authToken: string, timeout?: number): GetHeadersReturnType => ({
    headers: {
        Authorization: `Bearer ${authToken}`,
    },
    timeout,
});

export default {
    getHeaders,
};
