import React from 'react';

import { ContainerConstants } from 'lib/Constants';
import TabButtonContainer from './TabButtonContainer';
import ContentContainer from './ContentContainer';

import ProfileStyles from './_ProfileStyles.module.scss';

const Profile = (): JSX.Element => {
    const {
        profilePage: {
            header,
            subheader,
        },
    } = ContainerConstants;

    return (
        <div className={ProfileStyles.container}>
            <h1>
                {header}
            </h1>

            <p>
                {subheader}
            </p>

            <div className={ProfileStyles.subcontainer}>
                <TabButtonContainer />

                <ContentContainer />
            </div>
        </div>
    );
};

export default Profile;
