import React, { useState } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { ReactComponent as VoucherIcon } from '../../assets/icons/tags.svg';

import VoucherCardStyles from './_VoucherCardStyles.module.scss';

interface VoucherCardProps {
    expiryDate: string;
    externalTitle: string;
    redeemed: boolean;
    redeemedDate: string;
    thumbnailImage: string;
    grayOutThumbnail?: boolean;
    className?: string;
    onClick: (params: any) => void;
}

const VoucherCard = (props: VoucherCardProps): JSX.Element => {
    const {
        expiryDate,
        externalTitle,
        redeemed,
        redeemedDate,
        thumbnailImage,
        grayOutThumbnail,
        className,
        onClick,
    } = props;

    const [thumbnailImageUrl, setThumbnailImageUrl] = useState(thumbnailImage);

    dayjs.extend(utc);

    const renderDescription = () => {
        switch (redeemed) {
            case true: {
                return (
                    <p className={VoucherCardStyles['redeemed-description']}>
                        Successfully redeemed on
                        {' '}
                        {dayjs(redeemedDate).format('DD MMM YYYY').toUpperCase()}
                        .
                    </p>
                );
            }

            case false: {
                const isExpired = dayjs().utc().startOf('day').isAfter(expiryDate);
                return (
                    <p className={isExpired ? VoucherCardStyles['expired-description'] : ''}>
                        {isExpired ? 'Expired on' : 'Valid till'}
                        {' '}
                        {dayjs(expiryDate).format('DD MMM YYYY').toUpperCase()}
                        .
                        {isExpired ? '' : ' T&Cs apply.'}
                    </p>
                );
            }

            default: return null;
        }
    };

    return (
        <button
            type='button'
            className={`${VoucherCardStyles.container} ${className || ''}`}
            onClick={onClick}
        >
            {
                thumbnailImageUrl
                    ? (
                        <img
                            src={thumbnailImageUrl}
                            alt='thumbnail'
                            onError={() => setThumbnailImageUrl('')}
                            style={{
                                filter: grayOutThumbnail ? 'grayscale(1)' : '',
                            }}
                        />
                    )
                    : <VoucherIcon />
            }

            <div className={VoucherCardStyles['content-container']}>
                <div className={VoucherCardStyles['text-container']}>
                    {externalTitle}

                    {renderDescription()}
                </div>

                <VoucherIcon />
            </div>
        </button>
    );
};

export default VoucherCard;
