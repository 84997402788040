import { put, call, takeEvery } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SagaWatcherReturnType } from 'sagas/types';
import AuthGateway from 'api/Auth';
import CombinedActions from 'redux/CombinedActions';
import { SignUpReduxParams } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';

import NavActions from 'lib/NavActions';

export default function* watchSignUp(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authSignUpAttempt', handleSignUp, api);
}

function* handleSignUp(api: AuthGateway, data: PayloadAction<SignUpReduxParams>) {
    const {
        email,
        password,
        fullName,
        phoneNumber,
    } = data.payload;

    if (!email || !password || !fullName || !phoneNumber) {
        yield put(CombinedActions.authSignUpFailure('Please fill in all the fields!'));
        return;
    }

    const response = yield* call([api, api.signUp], data.payload);

    if (response.status === GatewayResponseStatus.Error) {
        yield put(CombinedActions.authSignUpFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(CombinedActions.authSignUpSuccess());
        yield put(CombinedActions.authLoginAttempt({
            email,
            password,
        }));
        toast.success('Sign Up successful!');
        NavActions.navToLogin();
    }
}
