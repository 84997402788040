import config from 'config';

import AuthGateway from './Auth';
import ProfileGateway from './Profile';

const baseUrl = config.baseUrl as string;

const auth = new AuthGateway(baseUrl);
const profile = new ProfileGateway(baseUrl);

export default {
    auth,
    profile,
};
