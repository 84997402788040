import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';

export interface LoginAPIParams {
    email: string;
    password: string;
}

export interface LoginAPIResponse {
    token: string;
    user: {
        fullName: string;
        email: string;
    };
}

export interface SignUpAPIParams {
    fullName: string;
    email: string;
    password: string;
    phoneNumber: string;
}

export interface VerifyOtpAPIParams {
    email: string;
    otp: number;
}

export interface ResetPasswordAPIParams {
    email: string;
    otp: number;
    newPassword: string;
}

export abstract class IAuthGateway extends Gateway {
    abstract login(params: LoginAPIParams): GatewayResponse<LoginAPIResponse | null>;

    abstract signUp(params: SignUpAPIParams): GatewayResponse<null>;

    abstract forgotPassword(email: string): GatewayResponse<string | null>;

    abstract verifyOtp(params: VerifyOtpAPIParams): GatewayResponse<string | null>;

    abstract resetPassword(params: ResetPasswordAPIParams): GatewayResponse<string | null>;
}
