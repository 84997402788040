import React, { CSSProperties } from 'react';
import { Spinner } from 'reactstrap';

import CTAButtonStyles from './_CTAButtonStyles.module.scss';

interface CTAButtonProps {
    onClick: () => void;
    label: string;
    isLoading?: boolean;
    outline?: boolean;
    ghost?: boolean;
    style?: CSSProperties;
    icon?: React.ReactNode;
    className?: string;
}

const CTAButton = (props: CTAButtonProps): JSX.Element => {
    const {
        onClick,
        label,
        isLoading = false,
        outline = false,
        ghost = false,
        style,
        icon,
        className,
    } = props;

    let buttonClassName = CTAButtonStyles.button;
    if (outline) {
        buttonClassName = CTAButtonStyles['outline-button'];
    } else if (ghost) {
        buttonClassName = CTAButtonStyles['ghost-button'];
    }

    return (
        <button
            type='button'
            onClick={onClick}
            className={`${buttonClassName} ${className}`}
            style={style}
        >
            {icon}
            {isLoading ? <Spinner /> : label}
        </button>
    );
};

export default CTAButton;
