import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store';
import CombinedSelectors from 'redux/CombinedSelectors';
import { ProfilePageTabReduxTypes } from 'redux/slices/profile/types';
import CombinedActions from 'redux/CombinedActions';
import UploadReceiptTab from './UploadReceiptTab';
import VoucherCollections from './VoucherCollections';
import VoucherHistory from './VoucherHistory';
import Settings from './Settings';
import VoucherDetailsModal from './VoucherDetailsModal';

import ContentContainerStyles from './_ContentContainerStyles.module.scss';

interface ContentContainerProps {
    currentTab: ProfilePageTabReduxTypes;
    setChangePasswordStatus: (status: boolean) => void;
}

const ContentContainer = (props: ContentContainerProps): JSX.Element => {
    const {
        currentTab,
        setChangePasswordStatus,
    } = props;

    useEffect(() => {
        setChangePasswordStatus(false);
    }, [currentTab]);

    const renderTabContent = () => {
        switch (currentTab) {
            case 'Upload Receipt': return <UploadReceiptTab />;

            case 'Voucher Collections': return <VoucherCollections />;

            case 'Voucher History': return <VoucherHistory />;

            case 'Settings': return <Settings />;

            default: return null;
        }
    };

    return (
        <div className={ContentContainerStyles.container}>
            {renderTabContent()}

            <VoucherDetailsModal />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentTab: CombinedSelectors.profile.getProfilePageCurrentTab(state.profile),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setChangePasswordStatus: (status: boolean) => dispatch(CombinedActions.profileSetChangePasswordStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);
