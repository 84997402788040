import React from 'react';

import config from 'config';
import NavActions from 'lib/NavActions';
import { ContainerConstants } from '../../lib/Constants';

import TermsAndConditionsStyles from './_TermsAndConditionsStyles.module.scss';

const TermsAndConditions = (): JSX.Element => {
    const {
        privacyPolicyExternalLink,
    } = config;

    const {
        navToPrivacyPolicy,
    } = NavActions;

    const {
        termsAndConditionsPage: {
            termsAndConditionsCopy,
            privacyPolicyCopy,
        },
    } = ContainerConstants;

    const renderTermsAndConditions = () => {
        return termsAndConditionsCopy.map((copy, index) => {
            const { header, steps, alphabetList } = copy;

            return (
                <div
                    key={index.toString().concat(header.substring(0, 10))}
                    className={TermsAndConditionsStyles['subcontainer-items']}
                >
                    <p>
                        {header}
                    </p>

                    {steps?.map((step, i) => {
                        const { item } = step;

                        return (
                            <span key={item.slice(1, 20).concat(i.toString())}>
                                <span>
                                    STEP
                                    {i + 1}
                                    :
                                </span>

                                <span>
                                    {item}
                                </span>
                            </span>
                        );
                    })}

                    {alphabetList?.map((listItem, idx) => {
                        const { item } = listItem;

                        return (
                            <span
                                className={TermsAndConditionsStyles['alpahabet-list-item']}
                                key={item.slice(1, 20).concat(idx.toString())}
                            >
                                {item}
                            </span>
                        );
                    })}
                </div>
            );
        });
    };

    const renderPrivacyPolicy = () => {
        const hereText = 'here';

        return (
            <p className={TermsAndConditionsStyles['privacy-policy-container']}>
                {privacyPolicyCopy.substring(0, privacyPolicyCopy.indexOf(hereText))}

                <a
                    href={privacyPolicyExternalLink}
                    onClick={(e) => {
                        e.preventDefault();
                        navToPrivacyPolicy();
                    }}
                >
                    {hereText}
                    {' '}
                </a>

                {privacyPolicyCopy.substring(privacyPolicyCopy.indexOf(hereText) + hereText.length)}
            </p>
        );
    };

    return (
        <div className={TermsAndConditionsStyles.container}>
            <h1>
                Terms & Conditions
            </h1>

            <div className={TermsAndConditionsStyles.subcontainer}>
                {renderTermsAndConditions()}

                {renderPrivacyPolicy()}
            </div>

        </div>
    );
};

export default TermsAndConditions;
