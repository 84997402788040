import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import ProfileGateway from 'api/Profile';
import CombinedActions from 'redux/CombinedActions';
import { GatewayResponseStatus } from 'api/types/types';
import CombinedSelectors from 'redux/CombinedSelectors';

import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { ChangePasswordReduxParams } from 'redux/slices/profile/types';

export default function* watchChangePassword(api: ProfileGateway): SagaWatcherReturnType {
    yield takeEvery('profile/profileChangePasswordAttempt', handleChangePassword, api);
}

function* handleChangePassword(api: ProfileGateway, data: PayloadAction<ChangePasswordReduxParams>) {
    const { Auth: { getAuthToken } } = Utils;

    const { navResetToLogin } = NavActions;

    const { oldPassword, newPassword, confirmPassword } = data.payload;

    let authToken = yield* select(CombinedSelectors.auth.getAuthToken);

    if (!authToken) authToken = getAuthToken() || '';

    if (!authToken) {
        yield put(CombinedActions.profileChangePasswordFailure('Something went wrong. Please try again later'));
        navResetToLogin();
        return;
    }

    const response = yield* call([api, api.changePassword], { authToken, oldPassword, newPassword, confirmPassword });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(CombinedActions.profileChangePasswordFailure(response.message || 'Something went wrong. Please try again later'));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            yield put(CombinedActions.profileChangePasswordSuccess());
            yield put(CombinedActions.profileGetUserInfoAttempt());
            return;
        }

        yield put(CombinedActions.profileChangePasswordFailure('Something went wrong. Please try again later'));
    }
}
