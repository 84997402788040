import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    LoginReduxParams,
    AuthReduxState,
    AuthPageTabReduxTypes,
    SignUpReduxParams,
    ForgotPasswordReduxParams,
    VerifyOtpReduxParams,
    ResetPasswordReduxParams,
} from './types';

const initialState: AuthReduxState = {
    loadings: {
        login: false,
        startup: true,
        signUp: false,
        forgotPassword: false,
        verifyOtp: false,
        resetPassword: false,
    },
    authToken: '',
    authPageCurrentTab: 'Login',
    errors: {
        login: '',
        startup: '',
        signUp: '',
        forgotPassword: '',
        verifyOtp: '',
        resetPassword: '',
    },
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authStartupAttempt: (state) => {
            state.loadings.startup = true;
            state.errors.startup = '';
        },
        authStartupSuccess: (state, action: PayloadAction<string>) => {
            state.loadings.startup = false;
            state.authToken = action.payload;
        },
        authStartupFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.startup = false;
            if (action.payload) state.errors.startup = action.payload;
        },
        authLoginAttempt: (state, _action: PayloadAction<LoginReduxParams>) => {
            state.loadings.login = true;
            state.errors.login = '';
        },
        authLoginSuccess: (state, action: PayloadAction<string>) => {
            state.loadings.login = false;
            state.authToken = action.payload;
        },
        authLoginFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.login = false;
            if (action.payload) {
                state.errors.login = action.payload;
            }
        },
        authClearAuthToken: (state) => {
            state.authToken = '';
        },
        authSetAuthPageCurrentTab: (state, action: PayloadAction<AuthPageTabReduxTypes>) => {
            state.authPageCurrentTab = action.payload;
        },
        authSignUpAttempt: (state, _action: PayloadAction<SignUpReduxParams>) => {
            state.loadings.signUp = true;
            state.errors.signUp = '';
        },
        authSignUpSuccess: (state) => {
            state.loadings.signUp = false;
        },
        authSignUpFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.signUp = false;
            if (action.payload) state.errors.signUp = action.payload;
        },
        authForgotPasswordAttempt: (state, _action: PayloadAction<ForgotPasswordReduxParams>) => {
            state.loadings.forgotPassword = true;
            state.errors.forgotPassword = '';
        },
        authForgotPasswordSuccess: (state) => {
            state.loadings.forgotPassword = false;
            state.errors.forgotPassword = '';
        },
        authForgotPasswordFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.forgotPassword = false;
            if (action.payload) {
                state.errors.forgotPassword = action.payload;
            }
        },
        authVerifyOtpAttempt: (state, _action: PayloadAction<VerifyOtpReduxParams>) => {
            state.loadings.verifyOtp = true;
            state.errors.verifyOtp = '';
        },
        authVerifyOtpSuccess: (state) => {
            state.loadings.verifyOtp = false;
            state.errors.verifyOtp = '';
        },
        authVerifyOtpFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.verifyOtp = false;
            if (action.payload) {
                state.errors.verifyOtp = action.payload;
            }
        },
        authResetPasswordAttempt: (state, _action: PayloadAction<ResetPasswordReduxParams>) => {
            state.loadings.resetPassword = true;
            state.errors.resetPassword = '';
        },
        authResetPasswordSuccess: (state) => {
            state.loadings.resetPassword = false;
            state.errors.resetPassword = '';
        },
        authResetPasswordFailure: (state, action: PayloadAction<string | undefined>) => {
            state.loadings.resetPassword = false;
            if (action.payload) {
                state.errors.resetPassword = action.payload;
            }
        },
    },
});

export type AuthState = typeof initialState;

export default {
    actions: authSlice.actions,
    reducers: authSlice.reducer,
};
