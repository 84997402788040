import Gateway from 'api/types/Gateway';
import { GatewayResponse } from 'api/types/types';
import { ChangePasswordReduxParams, UpdateUserInfoReduxParams } from 'redux/slices/profile/types';

export interface GetVouchersAPIParams {
    authToken: string;
    status: number;
    index: number;
}

export interface IVoucher {
    bannerImage: string;
    claimed: boolean;
    claimedByEmail: string;
    claimedByUserId: string;
    claimedDate: string;
    code: string;
    description: string;
    expiryDate: string;
    externalTitle: string;
    id: string;
    internalTitle: string;
    redeemed: boolean;
    redeemedDate: string;
    status: number;
    thumbnailImage: string;
    tnc: string;
    voucherCodeid: string;
}
export interface GetVouchersAPIResponse {
    index: number;
    maxIndex: number;
    data: IVoucher[];
}

export interface GetUserInfoAPIParams {
    authToken: string;
}

export interface GetUserInfoAPIResponse {
    userId: string;
    email: string;
    role: number;
    fullName: string;
    phoneNumber: string;
}

export interface SubmitReceiptAPIParams {
    authToken: string;
    outlet: string;
    item: string;
    amount: number;
    date: string;
    receipt: string;
}

export interface RedeemVoucherAPIParams {
    authToken: string;
    voucherCodeId: string;
}
export type UpdateUserInfoAPIParams = UpdateUserInfoReduxParams & { authToken: string };

export type ChangePasswordAPIParams = ChangePasswordReduxParams & { authToken: string };

export abstract class IProfileGateway extends Gateway {
    abstract getVouchers(params: GetVouchersAPIParams): GatewayResponse<GetVouchersAPIResponse | null>;

    abstract getUserInfo(params: GetUserInfoAPIParams): GatewayResponse<GetUserInfoAPIResponse | null>;

    abstract redeemVoucher(params: RedeemVoucherAPIParams): GatewayResponse<null>;

    abstract updateUserInfo(params: UpdateUserInfoAPIParams): GatewayResponse<string | null>;

    abstract changePassword(params: ChangePasswordAPIParams): GatewayResponse<string | null>;
}
