import { ApiResponse } from 'apisauce';

import { GatewayResponse } from 'api/types/types';

import { LoginAPIParams, LoginAPIResponse, IAuthGateway, SignUpAPIParams, VerifyOtpAPIParams, ResetPasswordAPIParams } from './AuthBase';

export default class AuthGateway extends IAuthGateway {
    async login(params: LoginAPIParams): GatewayResponse<LoginAPIResponse> {
        const response: ApiResponse<LoginAPIResponse> = await this.api.post('auth/customerLogin', params);
        return this.process(response);
    }

    async signUp(params: SignUpAPIParams): GatewayResponse<null> {
        const response: ApiResponse<null> = await this.api.post('auth/signUp', params);
        return this.process(response);
    }

    async forgotPassword(email: string): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('auth/forgotPassword', { email });
        return this.process(response);
    }

    async verifyOtp(params: VerifyOtpAPIParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('auth/verifyOtp', params);
        return this.process(response);
    }

    async resetPassword(params: ResetPasswordAPIParams): GatewayResponse<string | null> {
        const response: ApiResponse<string | null> = await this.api.post('auth/updateForgottenPassword', params);
        return this.process(response);
    }
}
