import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import ProfileGateway from 'api/Profile';
import CombinedActions from 'redux/CombinedActions';
import { GatewayResponseStatus } from 'api/types/types';
import CombinedSelectors from 'redux/CombinedSelectors';

import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';

export default function* watchGetUserInfo(api: ProfileGateway): SagaWatcherReturnType {
    yield takeEvery('profile/profileGetUserInfoAttempt', handleGetUserInfo, api);
}

function* handleGetUserInfo(api: ProfileGateway) {
    const { Auth: { getAuthToken, clearAuthToken } } = Utils;

    const { navResetToLogin } = NavActions;

    let authToken = yield* select(CombinedSelectors.auth.getAuthToken);

    if (!authToken) authToken = getAuthToken() || '';

    if (!authToken) {
        yield put(CombinedActions.profileGetUserInfoFailure('Something went wrong. Please try again later'));
        return;
    }

    const response = yield* call([api, api.getUserInfo], { authToken });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(CombinedActions.profileGetUserInfoFailure(response.message || 'Something went wrong. Please try again later'));
        yield put(CombinedActions.authClearAuthToken());
        clearAuthToken();
        navResetToLogin();
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            yield put(CombinedActions.profileGetUserInfoSuccess(response.data));
            return;
        }

        yield put(CombinedActions.profileGetUserInfoFailure('Something went wrong. Please try again later'));
        navResetToLogin();
    }
}
