import React, { useState } from 'react';
import { Form, FormGroup, FormFeedback, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { object, string } from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';

import { AppDispatch, RootState } from 'redux/store';
import CombinedSelectors from 'redux/CombinedSelectors';
import CombinedActions from 'redux/CombinedActions';
import CTAButton from 'components/CTAButton';
import { SignUpReduxParams } from 'redux/slices/auth/types';
import NavActions from 'lib/NavActions';

import config from 'config';
import { ContainerConstants, NavigationConstants } from 'lib/Constants';
import SignUpTabContentStyles from './_SignUpTabContentStyles.module.scss';

interface SignUpTabContentProps {
    signUpIsLoading: boolean;
    signUpError: string;
    signUp: (params: SignUpReduxParams) => void;
}

const SignUpTabContent = (props: SignUpTabContentProps): JSX.Element => {
    const {
        signUpIsLoading,
        signUpError,
        signUp,
    } = props;

    const {
        recaptchaSiteKey,
    } = config;

    const [fullName, setFullName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    const [fullNameInvalid, setFullNameInvalid] = useState(false);
    const [emailAddressInvalid, setEmailAddressInvalid] = useState(false);
    const [passwordInvalid, setPasswordInvalid] = useState(false);
    const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState(false);
    const [phoneNumberInvalid, setPhoneNumberInvalid] = useState(false);

    const [captchaCompleted, setCaptchaCompleted] = useState(false);

    const [captchaInvalid, setCaptchaInvalid] = useState(false);

    const {
        navToTermsAndConditions,
    } = NavActions;

    const passwordRegex = new RegExp(password.replace(/[[\]{}()*+?|^$.\\]/g, (character) => (`\\${character}`)));

    const signUpFormSchema = object({
        fullName: string().required().min(1).max(50),
        email: string().required().email().matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g),
        password: string().min(6).max(24).required(),
        confirmPassword: string().min(6).max(24).required()
            .matches(passwordRegex),
        phoneNumber: string().min(8).max(12).required(),
    });

    const renderTerms = () => {
        const { authPage: {
            signUpTerms,
        } } = ContainerConstants;

        const {
            termsAndConditionsPage,
        } = NavigationConstants;

        const termsAndConditionsText = 'Terms And Conditions';
        const privacyPolicyText = 'Privacy Policy';

        return (
            <>
                {signUpTerms.substring(0, signUpTerms.indexOf(termsAndConditionsText))}

                <a
                    onClick={(e) => {
                        e.preventDefault();
                        navToTermsAndConditions();
                    }}
                    href={termsAndConditionsPage}
                >
                    {termsAndConditionsText}
                </a>

                {signUpTerms.substring(signUpTerms.indexOf(termsAndConditionsText) + termsAndConditionsText.length, signUpTerms.indexOf(privacyPolicyText))}

                <a
                    onClick={(e) => {
                        e.preventDefault();
                        navToTermsAndConditions();
                    }}
                    href={termsAndConditionsPage}
                >
                    {privacyPolicyText}
                </a>

                {signUpTerms.substring(signUpTerms.indexOf(privacyPolicyText) + privacyPolicyText.length)}
            </>
        );
    };

    const handleCaptchaComplete = () => {
        setCaptchaCompleted(true);
        setCaptchaInvalid(false);
    };

    const handleSignUpClick = async () => {
        const validateFullNamePromise = new Promise((resolve, reject) => {
            try {
                signUpFormSchema.validateAt('fullName', { fullName })
                    .then(
                        (value) => {
                            setFullNameInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setFullNameInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        const validateEmailPromise = new Promise((resolve, reject) => {
            try {
                signUpFormSchema.validateAt('email', { email: emailAddress })
                    .then(
                        (value) => {
                            setEmailAddressInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setEmailAddressInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        const validatePasswordPromise = new Promise((resolve, reject) => {
            try {
                signUpFormSchema.validateAt('password', { password })
                    .then(
                        (value) => {
                            setPasswordInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setPasswordInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        const validateConfirmPasswordPromise = new Promise((resolve, reject) => {
            try {
                signUpFormSchema.validateAt('confirmPassword', { confirmPassword })
                    .then(
                        (value) => {
                            setConfirmPasswordInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setConfirmPasswordInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        const validatePhoneNumberPromise = new Promise((resolve, reject) => {
            try {
                signUpFormSchema.validateAt('phoneNumber', { phoneNumber })
                    .then(
                        (value) => {
                            setPhoneNumberInvalid(false);
                            resolve(value);
                        },
                        (reason) => {
                            setPhoneNumberInvalid(true);
                            reject(reason);
                        },
                    );
            } catch (error) {
                reject(error);
            }
        });

        await Promise.allSettled([
            validateFullNamePromise,
            validateEmailPromise,
            validatePasswordPromise,
            validateConfirmPasswordPromise,
            validatePhoneNumberPromise,
        ])
            .then((results) => {
                const fieldInvalid = results.find((result) => result.status === 'rejected');

                if (!captchaCompleted) {
                    setCaptchaInvalid(true);
                }

                if (!fieldInvalid && captchaCompleted) {
                    signUp({
                        fullName,
                        email: emailAddress,
                        password,
                        phoneNumber,
                    });
                }
            });
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') handleSignUpClick();

        if (e.currentTarget.name === 'fullName' && fullNameInvalid) setFullNameInvalid(false);
        if (e.currentTarget.name === 'password' && passwordInvalid) setPasswordInvalid(false);
        if (e.currentTarget.name === 'emailAddress' && emailAddressInvalid) setEmailAddressInvalid(false);
        if (e.currentTarget.name === 'confirmPassword' && confirmPasswordInvalid) setConfirmPasswordInvalid(false);
        if (e.currentTarget.name === 'phoneNumber' && phoneNumberInvalid) setPhoneNumberInvalid(false);
    };

    return (
        <div className={SignUpTabContentStyles.container}>
            <Form>
                <FormGroup className={SignUpTabContentStyles['form-group']}>
                    <Label
                        className={SignUpTabContentStyles.label}
                        for='fullName'
                    >
                        Full Name*
                    </Label>

                    <Input
                        type='text'
                        name='fullName'
                        placeholder='Full Name*'
                        value={fullName}
                        onChange={(e) => setFullName(e.currentTarget.value)}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                        invalid={fullNameInvalid || undefined}
                        autoCapitalize='words'
                    />

                    <FormFeedback invalid={fullNameInvalid ? 'true' : undefined}>
                        Please enter full name!
                    </FormFeedback>
                </FormGroup>

                <FormGroup className={SignUpTabContentStyles['form-group']}>
                    <Label
                        className={SignUpTabContentStyles.label}
                        for='email'
                    >
                        Email*
                    </Label>

                    <Input
                        type='text'
                        name='emailAddress'
                        placeholder='Email*'
                        value={emailAddress}
                        onChange={(e) => setEmailAddress(e.currentTarget.value)}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                        invalid={emailAddressInvalid || undefined}
                        autoCapitalize='off'
                        inputMode='email'
                    />

                    <FormFeedback invalid={emailAddressInvalid ? 'true' : undefined}>
                        Please enter email address!
                    </FormFeedback>
                </FormGroup>

                <FormGroup className={SignUpTabContentStyles['form-group']}>
                    <Label
                        className={SignUpTabContentStyles.label}
                        for='password'
                    >
                        Password*
                    </Label>

                    <Input
                        type='password'
                        name='password'
                        placeholder='Password*'
                        value={password}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                        invalid={passwordInvalid || undefined}
                    />

                    <FormFeedback invalid={passwordInvalid ? 'true' : undefined}>
                        Invalid password! Please enter between 6 to 24 characters.
                    </FormFeedback>
                </FormGroup>

                <FormGroup className={SignUpTabContentStyles['form-group']}>
                    <Label
                        className={SignUpTabContentStyles.label}
                        for='confirmPassword'
                    >
                        Confirm Password*
                    </Label>

                    <Input
                        type='password'
                        name='confirmPassword'
                        placeholder='Confirm Password*'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.currentTarget.value)}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                        invalid={confirmPasswordInvalid || undefined}
                    />

                    <FormFeedback invalid={confirmPasswordInvalid ? 'true' : undefined}>
                        Password does not match!
                    </FormFeedback>
                </FormGroup>

                <FormGroup className={SignUpTabContentStyles['form-group']}>
                    <Label
                        className={SignUpTabContentStyles.label}
                        for='phoneNumber'
                    >
                        Contact No.*
                    </Label>

                    <Input
                        type='text'
                        name='phoneNumber'
                        placeholder='Contact No.*'
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                        onKeyDown={(e) => handleOnKeyDown(e)}
                        invalid={phoneNumberInvalid || undefined}
                        inputMode='tel'
                    />

                    <FormFeedback invalid={phoneNumberInvalid ? 'true' : undefined}>
                        Please enter contact number!
                    </FormFeedback>
                </FormGroup>
            </Form>

            <ReCAPTCHA
                className={SignUpTabContentStyles.captcha}
                sitekey={recaptchaSiteKey || ''}
                onChange={handleCaptchaComplete}
            />

            {captchaInvalid
                && (
                    <p className={SignUpTabContentStyles['error-message']}>
                        Complete Captcha before proceeding.
                    </p>
                )}

            <p className={SignUpTabContentStyles['terms-paragraph']}>
                {renderTerms()}
            </p>

            <CTAButton
                label='Get Started'
                onClick={handleSignUpClick}
                isLoading={signUpIsLoading}
            />

            {signUpError
                && (
                    <p className={SignUpTabContentStyles.error}>
                        {signUpError}
                    </p>
                )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    signUpIsLoading: CombinedSelectors.auth.getSignUpAttempting(state.auth),
    signUpError: CombinedSelectors.auth.getSignUpError(state.auth),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    signUp: (params: SignUpReduxParams) => dispatch(CombinedActions.authSignUpAttempt(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpTabContent);
