import { fork } from 'redux-saga/effects';

import ProfileGateway from 'api/Profile';
import { RootSagaReturnType } from 'sagas/types';

import WatchGetVoucherCollection from './GetVoucherCollection';
import WatchGetVoucherHistory from './GetVoucherHistory';
import WatchGetUserInfo from './GetUserInfo';
import WatchSubmitReceipt from './SubmitReceipt';
import WatchRedeemVoucher from './RedeemVoucher';
import watchUpdateUserInfo from './UpdateUserInfo';
import watchChangePassword from './ChangePassword';

export default (api: ProfileGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(WatchGetVoucherCollection, api);
        yield fork(WatchGetVoucherHistory, api);
        yield fork(WatchGetUserInfo, api);
        yield fork(WatchSubmitReceipt);
        yield fork(WatchRedeemVoucher, api);
        yield fork(watchUpdateUserInfo, api);
        yield fork(watchChangePassword, api);
    }

    return {
        rootSaga,
    };
};
