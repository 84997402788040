import React from 'react';
import { connect } from 'react-redux';

import CombinedActions from 'redux/CombinedActions';
import CombinedSelectors from 'redux/CombinedSelectors';
import { AuthPageTabReduxTypes } from 'redux/slices/auth/types';
import { AppDispatch, RootState } from 'redux/store';
import { ContainerConstants } from 'lib/Constants';
import LoginTabContent from './LoginTabContent';
import SignUpTabContent from './SignUpTabContent';

import AuthStyles from './_AuthStyles.module.scss';

interface AuthProps {
    currentTab: AuthPageTabReduxTypes;
    setCurrentTab: (tab: AuthPageTabReduxTypes) => void;
}

const Auth = (props: AuthProps): JSX.Element => {
    const {
        currentTab,
        setCurrentTab,
    } = props;

    const {
        authPage: {
            header,
            subheader,
        },
    } = ContainerConstants;

    return (
        <div className={AuthStyles.container}>
            <h1>
                {header}
            </h1>

            <p>
                {subheader}
            </p>

            <div className={AuthStyles.subcontainer}>
                <button
                    type='button'
                    onClick={() => setCurrentTab('Login')}
                    className={currentTab === 'Login' ? AuthStyles['is-active'] : ''}
                >
                    Login
                </button>

                <button
                    type='button'
                    onClick={() => setCurrentTab('Sign Up')}
                    className={currentTab === 'Sign Up' ? AuthStyles['is-active'] : ''}
                >
                    Sign Up
                </button>

                <div className={AuthStyles['tab-content-container']}>
                    {currentTab === 'Login' ? <LoginTabContent /> : <SignUpTabContent />}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentTab: CombinedSelectors.auth.getAuthPageCurrentTab(state.auth),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setCurrentTab: (tab: AuthPageTabReduxTypes) => dispatch(CombinedActions.authSetAuthPageCurrentTab(tab)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
