import React from 'react';

import NavBarNavButtonStyles from './_NavBarNavButtonStyles.module.scss';

interface NavBarNavButtonProps {
    href?: string;
    label: string;
    onClick?: () => void;
    isActive?: boolean;
}

const NavBarNavButton = (props: NavBarNavButtonProps): JSX.Element => {
    const {
        href,
        label,
        onClick,
        isActive,
    } = props;

    return (
        <a
            href={href}
            data-label={label} // Passing label to the ::before selector so that the text doesn't shift when bolded
            className={`${NavBarNavButtonStyles.a}${isActive ? ` ${NavBarNavButtonStyles['is-active']}` : ''}`}
            onClick={(e) => {
                if (onClick) {
                    e.preventDefault();
                    onClick();
                }
            }}
        >
            {label}
        </a>
    );
};

export default NavBarNavButton;
