import { put, call, takeEvery, select } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import ProfileGateway from 'api/Profile';
import CombinedActions from 'redux/CombinedActions';
import { GatewayResponseStatus } from 'api/types/types';
import CombinedSelectors from 'redux/CombinedSelectors';

import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';
import { PayloadAction } from '@reduxjs/toolkit';
import { UpdateUserInfoReduxParams } from 'redux/slices/profile/types';
import { toast } from 'react-toastify';

export default function* watchUpdateUserInfo(api: ProfileGateway): SagaWatcherReturnType {
    yield takeEvery('profile/profileUpdateUserInfoAttempt', handleUpdateUserInfo, api);
}

function* handleUpdateUserInfo(api: ProfileGateway, data: PayloadAction<UpdateUserInfoReduxParams>) {
    const { Auth: { getAuthToken } } = Utils;

    const { navResetToLogin } = NavActions;

    const { fullName, phoneNumber } = data.payload;

    let authToken = yield* select(CombinedSelectors.auth.getAuthToken);

    if (!authToken) authToken = getAuthToken() || '';

    if (!authToken) {
        yield put(CombinedActions.profileUpdateUserInfoFailure('Something went wrong. Please try again later'));
        navResetToLogin();
        return;
    }

    const response = yield* call([api, api.updateUserInfo], { authToken, fullName, phoneNumber });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(CombinedActions.profileUpdateUserInfoFailure(response.message || 'Something went wrong. Please try again later'));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            toast.success('Profile updated successfully!');
            yield put(CombinedActions.profileUpdateUserInfoSuccess());
            yield put(CombinedActions.profileGetUserInfoAttempt());
            return;
        }

        yield put(CombinedActions.profileUpdateUserInfoFailure('Something went wrong. Please try again later'));
    }
}
