import { AuthState } from '.';
import { AuthPageTabReduxTypes } from './types';

const getStartupAttempting = (state: AuthState): boolean => state.loadings.startup || false;
const getStartupError = (state: AuthState): string => state.errors.startup || '';

const getLoginAttempting = (state: AuthState): boolean => state.loadings.login || false;
const getLoginError = (state: AuthState): string => state.errors.login || '';
const getAuthToken = (state: AuthState): string => state.authToken || '';

const getAuthPageCurrentTab = (state: AuthState): AuthPageTabReduxTypes => state.authPageCurrentTab || 'Login';

const getSignUpAttempting = (state: AuthState): boolean => state.loadings.signUp || false;
const getSignUpError = (state: AuthState): string => state.errors.signUp || '';

const getForgotPasswordAttempting = (state: AuthState): boolean => state.loadings.forgotPassword || false;
const getForgotPasswordError = (state: AuthState): string => state.errors.forgotPassword || '';

const getVerifyOtpAttempting = (state: AuthState): boolean => state.loadings.verifyOtp || false;
const getVerifyOtpError = (state: AuthState): string => state.errors.verifyOtp || '';

const getResetPasswordAttempting = (state: AuthState): boolean => state.loadings.resetPassword || false;
const getResetPasswordError = (state: AuthState): string => state.errors.resetPassword || '';

export default {
    getStartupAttempting,
    getStartupError,

    getLoginAttempting,
    getLoginError,
    getAuthToken,

    getAuthPageCurrentTab,

    getSignUpAttempting,
    getSignUpError,

    getForgotPasswordAttempting,
    getForgotPasswordError,

    getVerifyOtpAttempting,
    getVerifyOtpError,

    getResetPasswordAttempting,
    getResetPasswordError,
};
