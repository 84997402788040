import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import CombinedActions from 'redux/CombinedActions';
import NavActions from 'lib/NavActions';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { VerifyOtpReduxParams } from 'redux/slices/auth/types';

export default function* watchVerifyOtp(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authVerifyOtpAttempt', handleVerifyOtp, api);
}

function* handleVerifyOtp(api: AuthGateway, data: PayloadAction<VerifyOtpReduxParams>) {
    const { email, otp } = data.payload;
    const numberOtp = Number(otp);
    const { navtoResetPassword } = NavActions;

    if (!email) {
        yield put(CombinedActions.authVerifyOtpFailure('Please enter your email'));
        return;
    }

    const response = yield* call([api, api.verifyOtp], { email, otp: numberOtp });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(CombinedActions.authVerifyOtpFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(CombinedActions.authVerifyOtpSuccess());
        toast.success('Verification successful!');

        navtoResetPassword(email, otp);
    }
}
