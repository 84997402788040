import React, { useEffect } from 'react';

import NavBar from 'layout/NavBar';
import AppRoot from 'containers/AppRoot';
import Footer from 'layout/Footer';

import './sass/global.css';
import { connect } from 'react-redux';
import { AppDispatch } from 'redux/store';
import CombinedActions from 'redux/CombinedActions';
import packageJson from '../package.json';

interface AppProps {
    startup: () => void;
}

const App = (props: AppProps): JSX.Element => {
    const {
        startup,
    } = props;

    useEffect(() => {
        console.log(`App version: ${packageJson.version} (${process.env.REACT_APP_ENV})`);

        startup();
    }, []);

    return (
        <div className='app-container'>
            <NavBar />

            <AppRoot />

            <Footer />
        </div>
    );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    startup: () => dispatch(CombinedActions.authStartupAttempt()),
});

export default connect(null, mapDispatchToProps)(App);
