import { put, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';
import CombinedActions from 'redux/CombinedActions';
import Utils from 'lib/Utils';

export default function* watchStartup(): SagaWatcherReturnType {
    yield takeEvery('auth/authStartupAttempt', handleStartup);
}

function* handleStartup() {
    const { Auth: { getAuthToken } } = Utils;

    const authToken = getAuthToken() || '';

    if (!authToken) {
        yield put(CombinedActions.authStartupFailure());
        return;
    }

    yield put(CombinedActions.authStartupSuccess(authToken));
}
