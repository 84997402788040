import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import dayjs from 'dayjs';

import CombinedActions from 'redux/CombinedActions';
import CombinedSelectors from 'redux/CombinedSelectors';
import { AppDispatch, RootState } from 'redux/store';
import { GetVoucherCollectionReduxParams, GetVoucherHistoryReduxParams, ProfilePageTabReduxTypes, RedeemVoucherReduxParams, VoucherDetailsReduxState } from 'redux/slices/profile/types';
import { GetVouchersAPIResponse } from 'api/ProfileBase';
import CTAButton from 'components/CTAButton';
import { ReactComponent as VoucherIcon } from '../../../../assets/icons/tags.svg';
import CloseIcon from '../../../../assets/icons/close.svg';

import VoucherDetailsModalStyles from './_VoucherDetailsModalStyles.module.scss';

interface VoucherDetailsModalProps {
    voucherDetails: VoucherDetailsReduxState;
    redeemVoucherIsLoading: boolean;
    redeemVoucherError: string;
    profilePageCurrentTab: ProfilePageTabReduxTypes;
    voucherCollection: GetVouchersAPIResponse;
    voucherHistory: GetVouchersAPIResponse;
    resetVoucherDetails: () => void;
    redeemVoucher: (params: RedeemVoucherReduxParams) => void;
    getVoucherCollection: (params: GetVoucherCollectionReduxParams) => void;
    getVoucherHistory: (params: GetVoucherHistoryReduxParams) => void;
    setVoucherDetails: (params: VoucherDetailsReduxState) => void;
    profileResetRedeemVoucherReduxState: () => void;
}

const VoucherDetailsModal = (props: VoucherDetailsModalProps): JSX.Element => {
    const {
        voucherDetails,
        redeemVoucherIsLoading,
        redeemVoucherError,
        profilePageCurrentTab,
        voucherCollection,
        voucherHistory,
        resetVoucherDetails,
        redeemVoucher,
        getVoucherCollection,
        getVoucherHistory,
        setVoucherDetails,
        profileResetRedeemVoucherReduxState,
    } = props;

    const {
        externalTitle,
        bannerImage,
        description,
        expiryDate,
        tnc,
        redeemed,
        redeemedDate,
        voucherCodeid,
        code,
    } = voucherDetails;

    const [bannerImageUrl, setBannerImageUrl] = useState('');
    const [showVoucherCode, setShowVoucherCode] = useState(false);

    useEffect(() => {
        setBannerImageUrl(bannerImage);
    }, [bannerImage]);

    const handleModalToggle = () => {
        const {
            index: voucherCollectionIndex,
        } = voucherCollection;

        const {
            index: voucherHistoryIndex,
        } = voucherHistory;

        resetVoucherDetails();
        profileResetRedeemVoucherReduxState();
        setShowVoucherCode(false);

        if (profilePageCurrentTab === 'Voucher Collections') getVoucherCollection({ status: 1, index: voucherCollectionIndex });

        if (profilePageCurrentTab === 'Voucher History') getVoucherHistory({ status: 2, index: voucherHistoryIndex });
    };

    const renderModalHeader = () => {
        return (
            <div className={VoucherDetailsModalStyles['modal-header-container']}>
                <div className={VoucherDetailsModalStyles['header-row']}>
                    {externalTitle}

                    <button
                        type='button'
                        onClick={handleModalToggle}
                    >
                        <img
                            src={CloseIcon}
                            alt='close icon'
                        />
                    </button>
                </div>

                {
                    bannerImageUrl
                        ? (
                            <img
                                src={bannerImageUrl}
                                alt='voucher banner'
                                onError={() => setBannerImageUrl('')}
                            />
                        )
                        : <VoucherIcon />
                }

                <p className={VoucherDetailsModalStyles.description}>
                    {description}
                </p>

                <div className={VoucherDetailsModalStyles['expiry-and-redeem-status']}>
                    {renderExpiryAndRedeemStatus()}
                </div>
            </div>
        );
    };

    const renderModalBody = () => {
        return (
            <div className={VoucherDetailsModalStyles['modal-body-container']}>
                <div className={VoucherDetailsModalStyles['modal-body-subcontainer']}>
                    <span>
                        Terms & Conditions
                    </span>

                    <p>
                        {tnc}
                    </p>
                </div>
            </div>
        );
    };

    const renderExpiryAndRedeemStatus = () => {
        switch (redeemed) {
            case true: {
                return (
                    <p>
                        Successfully redeemed on
                        {' '}
                        {dayjs(redeemedDate).format('DD MMM YYYY').toUpperCase()}
                        .
                    </p>
                );
            }

            case false: {
                const isExpired = dayjs().utc().startOf('day').isAfter(expiryDate);
                return (
                    <p className={isExpired ? VoucherDetailsModalStyles['expired-description'] : ''}>
                        {isExpired ? 'Expired on' : 'Valid till'}
                        {' '}
                        {dayjs(expiryDate).format('DD MMM YYYY').toUpperCase()}
                        .
                        {isExpired ? '' : ' T&Cs apply.'}
                    </p>
                );
            }

            default: return null;
        }
    };

    const handleRedeemCompleteClick = () => {
        setShowVoucherCode(false);
        setVoucherDetails({
            externalTitle,
            bannerImage,
            description,
            expiryDate,
            tnc,
            redeemed: true,
            redeemedDate,
            voucherCodeid,
            code,
        });
    };

    const renderModalFooter = () => {
        const renderFooterContent = () => {
            if (redeemVoucherError) {
                return (
                    <p className={VoucherDetailsModalStyles['error-message']}>
                        {redeemVoucherError}
                    </p>
                );
            }

            if (showVoucherCode) {
                return (
                    <div className={VoucherDetailsModalStyles['show-code-container']}>
                        <p className={VoucherDetailsModalStyles['footer-description']}>
                            <span>
                                E-Gift Card Codes
                            </span>

                            <span>
                                {code}
                            </span>

                            <span>
                                {`Expires ${dayjs(expiryDate).format('DD MMM YYYY')}.`}
                            </span>
                        </p>

                        <button
                            type='button'
                            onClick={handleRedeemCompleteClick}
                            className={VoucherDetailsModalStyles['redeem-complete-button']}
                        >
                            Redeem Complete
                        </button>
                    </div>
                );
            }

            switch (redeemed) {
                case true: {
                    return (
                        <p className={VoucherDetailsModalStyles['footer-description']}>
                            <span>
                                E-Gift Card Codes
                            </span>

                            <span>
                                Successfully Redeemed
                            </span>

                            <span>
                                {code}
                            </span>

                        </p>
                    );
                }

                case false: {
                    const isExpired = dayjs().utc().startOf('day').isAfter(expiryDate);

                    if (isExpired) {
                        return (
                            <p className={VoucherDetailsModalStyles['footer-description']}>
                                <span>
                                    E-Gift Card Codes
                                </span>

                                <span>
                                    Failed To Redeem
                                </span>

                                <span className={VoucherDetailsModalStyles.failed}>
                                    {code}
                                </span>
                            </p>
                        );
                    }

                    return (
                        <>
                            <CTAButton
                                label='Redeem Now'
                                onClick={() => {
                                    setShowVoucherCode(true);
                                    redeemVoucher({ voucherCodeId: voucherCodeid });
                                }}
                                className={VoucherDetailsModalStyles['redeem-button']}
                                isLoading={redeemVoucherIsLoading}
                            />

                            {redeemVoucherError
                                && (
                                    <p className={VoucherDetailsModalStyles['error-message']}>
                                        {redeemVoucherError}
                                    </p>
                                )}
                        </>
                    );
                }

                default: return null;
            }
        };

        return (
            <div className={VoucherDetailsModalStyles['modal-footer-container']}>
                {renderFooterContent()}
            </div>
        );
    };

    return (
        <Modal
            isOpen={voucherCodeid.length > 0}
            toggle={handleModalToggle}
            size='lg'
            className={VoucherDetailsModalStyles.container}
        >
            {renderModalHeader()}

            {renderModalBody()}

            {renderModalFooter()}
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    voucherDetails: CombinedSelectors.profile.getVoucherDetails(state.profile),
    redeemVoucherIsLoading: CombinedSelectors.profile.getRedeemVoucherAttempting(state.profile),
    redeemVoucherError: CombinedSelectors.profile.getRedeemVoucherError(state.profile),
    profilePageCurrentTab: CombinedSelectors.profile.getProfilePageCurrentTab(state.profile),
    voucherCollection: CombinedSelectors.profile.getVoucherCollection(state.profile),
    voucherHistory: CombinedSelectors.profile.getVoucherHistory(state.profile),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    resetVoucherDetails: () => dispatch(CombinedActions.profileResetVoucherDetails()),
    redeemVoucher: (params: RedeemVoucherReduxParams) => dispatch(CombinedActions.profileRedeemVoucherAttempt(params)),
    getVoucherCollection: (params: GetVoucherCollectionReduxParams) => dispatch(CombinedActions.profileGetVoucherCollectionAttempt(params)),
    getVoucherHistory: (params: GetVoucherHistoryReduxParams) => dispatch(CombinedActions.profileGetVoucherHistoryAttempt(params)),
    setVoucherDetails: (params: VoucherDetailsReduxState) => dispatch(CombinedActions.profileSetVoucherDetails(params)),
    profileResetRedeemVoucherReduxState: () => dispatch(CombinedActions.profileResetRedeemVoucherReduxState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherDetailsModal);
