import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Paginator from 'components/Paginator';
import { AppDispatch, RootState } from 'redux/store';
import CombinedSelectors from 'redux/CombinedSelectors';
import CombinedActions from 'redux/CombinedActions';
import { GetVouchersAPIResponse } from 'api/ProfileBase';
import { GetVoucherHistoryReduxParams, VoucherDetailsReduxState, VoucherStatusType } from 'redux/slices/profile/types';
import VoucherCard from 'components/VoucherCard';

import VoucherHistoryStyles from './_VoucherHistoryStyles.module.scss';

interface VoucherHistoryProps {
    voucherHistory: GetVouchersAPIResponse;
    getVoucherHistoryIsLoading: boolean;
    setIndex: (index: number) => void;
    getVoucherHistory: (params: GetVoucherHistoryReduxParams) => void;
    setVoucherDetails: (params: VoucherDetailsReduxState) => void;
}

const VoucherHistory = (props: VoucherHistoryProps): JSX.Element => {
    const {
        voucherHistory,
        getVoucherHistoryIsLoading,
        setIndex,
        getVoucherHistory,
        setVoucherDetails,
    } = props;

    const {
        index,
        maxIndex,
        data,
    } = voucherHistory;

    useEffect(() => {
        getVoucherHistory({
            status: VoucherStatusType.Inactive,
            index,
        });
    }, [index]);

    const renderVoucherContainer = () => {
        return (
            <div className={VoucherHistoryStyles['voucher-container']}>
                {getVoucherHistoryIsLoading
                    ? (
                        <div>
                            <div className={VoucherHistoryStyles['loading-skeleton']} />
                            <div className={VoucherHistoryStyles['loading-skeleton']} />
                            <div className={VoucherHistoryStyles['loading-skeleton']} />
                        </div>
                    )
                    : renderVoucherHistory()}
            </div>
        );
    };

    const renderVoucherHistory = () => {
        return data.map(voucher => {
            const {
                externalTitle,
                bannerImage,
                description,
                expiryDate,
                tnc,
                redeemed,
                redeemedDate,
                voucherCodeid,
                thumbnailImage,
                code,
            } = voucher;

            return (
                <VoucherCard
                    expiryDate={expiryDate}
                    externalTitle={externalTitle}
                    redeemed={redeemed}
                    redeemedDate={redeemedDate}
                    thumbnailImage={thumbnailImage}
                    key={voucherCodeid}
                    onClick={() => setVoucherDetails({
                        externalTitle,
                        bannerImage,
                        description,
                        expiryDate,
                        tnc,
                        redeemed,
                        redeemedDate,
                        voucherCodeid,
                        code,
                    })}
                    grayOutThumbnail
                />
            );
        });
    };

    const renderFooter = () => {
        if (data.length > 0) {
            return (
                <Paginator
                    index={index}
                    maxIndex={maxIndex}
                    setIndex={setIndex}
                />
            );
        }

        return (
            <div className={VoucherHistoryStyles['empty-list-message']}>
                {getVoucherHistoryIsLoading ? '' : 'No vouchers found'}
            </div>
        );
    };

    return (
        <div className={VoucherHistoryStyles.container}>
            <h2>
                Voucher History
            </h2>

            {renderVoucherContainer()}

            {renderFooter()}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    voucherHistory: CombinedSelectors.profile.getVoucherHistory(state.profile),
    getVoucherHistoryIsLoading: CombinedSelectors.profile.getVoucherHistoryAttempting(state.profile),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    setIndex: (index: number) => dispatch(CombinedActions.profileSetVoucherHistoryIndex(index)),
    getVoucherHistory: (params: GetVoucherHistoryReduxParams) => dispatch(CombinedActions.profileGetVoucherHistoryAttempt(params)),
    setVoucherDetails: (params: VoucherDetailsReduxState) => dispatch(CombinedActions.profileSetVoucherDetails(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VoucherHistory);
