import { fork } from 'redux-saga/effects';

import AuthGateway from 'api/Auth';
import { RootSagaReturnType } from 'sagas/types';

import WatchStartup from './Startup';
import WatchLogin from './Login';
import WatchSignUp from './SignUp';
import watchForgotPassword from './ForgotPassword';
import watchVerifyOtp from './VerifyOtp';
import watchResetPassword from './ResetPassword';

export default (api: AuthGateway): RootSagaReturnType => {
    function* rootSaga() {
        yield fork(WatchStartup);
        yield fork(WatchLogin, api);
        yield fork(WatchSignUp, api);
        yield fork(watchForgotPassword, api);
        yield fork(watchVerifyOtp, api);
        yield fork(watchResetPassword, api);
    }

    return {
        rootSaga,
    };
};
