import React from 'react';

import NavActions from 'lib/NavActions';
import CooperVisionProductsImage from '../../../../assets/images/coopervision-products.webp';

import CooperVisionProductsStyles from './_CooperVisionProductsStyles.module.scss';

const CooperVisionProducts = (): JSX.Element => {
    const {
        navToProducts,
    } = NavActions;

    return (
        <div className={CooperVisionProductsStyles.container}>
            <h2>
                CooperVision Products
            </h2>

            <button
                type='button'
                onClick={navToProducts}
                className={CooperVisionProductsStyles.button}
            >
                <img
                    src={CooperVisionProductsImage}
                    alt='cooper vision products'
                    width='100%'
                />
            </button>
        </div>
    );
};

export default CooperVisionProducts;
