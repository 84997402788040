import React from 'react';
import DatePicker from 'react-datepicker';

import './DatePickerStyles.css';

interface ReactDatePickerProps {
    selected: Date | null;
    onChange: (date: Date) => void;
    className: string;
    showIcon?: boolean;
    [key: string]: any;
}

const ReactDatePicker = (props: ReactDatePickerProps): JSX.Element => {
    const {
        selected,
        onChange,
        className,
        showIcon,
        ...restOfProps
    } = props;

    return (
        <DatePicker
            selected={selected}
            onChange={onChange}
            dateFormat='dd/MM/yyyy'
            className={className}
            showIcon={showIcon}
            {...restOfProps}
        />
    );
};

export default ReactDatePicker;
