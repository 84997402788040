import { put, call, takeEvery, select } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import { SagaWatcherReturnType } from 'sagas/types';
import ProfileGateway from 'api/Profile';
import CombinedActions from 'redux/CombinedActions';
import { GatewayResponseStatus } from 'api/types/types';
import { RedeemVoucherReduxParams } from 'redux/slices/profile/types';
import CombinedSelectors from 'redux/CombinedSelectors';

import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';

export default function* watchRedeemVoucher(api: ProfileGateway): SagaWatcherReturnType {
    yield takeEvery('profile/profileRedeemVoucherAttempt', handleRedeemVoucher, api);
}

function* handleRedeemVoucher(api: ProfileGateway, data: PayloadAction<RedeemVoucherReduxParams>) {
    const {
        voucherCodeId,
    } = data.payload;

    const { Auth: { getAuthToken } } = Utils;

    const { navResetToLogin } = NavActions;

    let authToken = yield* select(CombinedSelectors.auth.getAuthToken);

    if (!authToken) authToken = getAuthToken() || '';

    if (!authToken) {
        yield put(CombinedActions.profileRedeemVoucherFailure('Something went wrong. Please try again later'));
        navResetToLogin();
        return;
    }

    if (!voucherCodeId) {
        yield put(CombinedActions.profileRedeemVoucherFailure('Something went wrong. Please try again later'));
        return;
    }

    const response = yield* call([api, api.redeemVoucher], { ...data.payload, authToken });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(CombinedActions.profileRedeemVoucherFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        if (response.data) {
            yield put(CombinedActions.profileRedeemVoucherSuccess());
            return;
        }

        yield put(CombinedActions.profileRedeemVoucherFailure('Something went wrong. Please try again later'));
    }
}
