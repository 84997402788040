import React from 'react';
import { Provider } from 'react-redux';
import {
    createRoot,
} from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { ToastContainer } from 'react-toastify';

import { store } from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.css';
import './sass/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

import packageJson from '../package.json';

Sentry.init({
    dsn: 'https://e79e36a573572ed0233cb1faf6a240b7@o370650.ingest.sentry.io/4506308358635520',
    release: `${packageJson.name}@${packageJson.version}`,
    environment: process.env.REACT_APP_ENV,
    enabled: process.env.REACT_APP_ENV === 'production',
    tracesSampleRate: 1.0,
});

// Root element is hardcoded in the index.html file so this will never be null.
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ToastContainer theme='colored' toastStyle={{ color: '#fff' }} />

            <App />
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
