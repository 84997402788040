import React, { useState } from 'react';
import {
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    Accordion,
} from 'reactstrap';

import { ReactComponent as ChevronUpLogo } from '../../assets/icons/chevron-up.svg';

import CustomAccordionStyles from './_CustomAccordionStyles.module.scss';

interface CustomAccordionProps {
    data: {
        header: string | JSX.Element;
        body: string | JSX.Element;
    }[];
}

const CustomAccordion = (props: CustomAccordionProps): JSX.Element => {
    const {
        data,
    } = props;

    const [openedAccordions, setOpenedAccordions] = useState<string[]>([]);

    const toggle = (id: string) => {
        if (openedAccordions.includes(id)) {
            setOpenedAccordions(openedAccordions.filter(item => item !== id));
            return;
        }

        setOpenedAccordions([...openedAccordions, id]);
    };

    const renderAccordionItems = () => {
        return data.map((item, index) => {
            const { header, body } = item;

            return (
                <AccordionItem
                    key={header.toString()}
                    className={CustomAccordionStyles['accordion-item']}
                >
                    <AccordionHeader
                        targetId={index.toString()}
                        className={CustomAccordionStyles['accordion-header']}
                    >
                        {header}

                        <div>
                            <ChevronUpLogo />
                        </div>
                    </AccordionHeader>

                    <AccordionBody
                        accordionId={index.toString()}
                        className={CustomAccordionStyles['accordion-body']}
                    >
                        <div>
                            {body}
                        </div>
                    </AccordionBody>
                </AccordionItem>
            );
        });
    };

    return (
        <Accordion
            open={openedAccordions}
            toggle={toggle}
            className={CustomAccordionStyles.container}
        >
            {renderAccordionItems()}
        </Accordion>
    );
};

export default CustomAccordion;
