import { GetUserInfoAPIResponse, GetVouchersAPIResponse } from 'api/ProfileBase';

export interface ProfileReduxState {
    loadings: {
        getVoucherCollection: boolean;
        getVoucherHistory: boolean;
        getUserInfo: boolean;
        submitReceipt: boolean;
        updateUserInfo: boolean;
        redeemVoucher: boolean;
        changePassword: boolean;
    },
    profilePageCurrentTab: ProfilePageTabReduxTypes;
    userInfo: GetUserInfoAPIResponse;
    voucherCollection: GetVouchersAPIResponse;
    voucherHistory: GetVouchersAPIResponse;
    voucherDetails: VoucherDetailsReduxState,
    changePasswordStatus: boolean;
    errors: {
        getVoucherCollection: string;
        getVoucherHistory: string;
        getUserInfo: string;
        submitReceipt: string;
        updateUserInfo: string;
        redeemVoucher: string;
        changePassword: string;
    },
}

export type ProfilePageTabReduxTypes = 'Upload Receipt' | 'Voucher Collections' | 'Voucher History' | 'Settings';

export enum VoucherStatusType {
    'Active' = 1,
    'Inactive' = 2,
}

export interface GetVoucherCollectionReduxParams {
    status: VoucherStatusType.Active;
    index: number;
}

export interface GetVoucherHistoryReduxParams {
    status: VoucherStatusType.Inactive;
    index: number;
}

export interface UpdateUserInfoReduxParams {
    fullName: string;
    phoneNumber: string;
}

export interface ChangePasswordReduxParams {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface VoucherDetailsReduxState {
    externalTitle: string;
    bannerImage: string;
    description: string;
    expiryDate: string;
    tnc: string;
    redeemed: boolean | null;
    redeemedDate: string;
    voucherCodeid: string;
    code: string;
}

export interface RedeemVoucherReduxParams {
    voucherCodeId: string;
}
