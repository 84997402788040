import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import CombinedActions from 'redux/CombinedActions';
import NavActions from 'lib/NavActions';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ResetPasswordReduxParams } from 'redux/slices/auth/types';

export default function* watchResetPassword(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authResetPasswordAttempt', handleResetPassword, api);
}

function* handleResetPassword(api: AuthGateway, data: PayloadAction<ResetPasswordReduxParams>) {
    const { email, otp, newPassword } = data.payload;
    const numberOtp = Number(otp);

    const { navToLogin } = NavActions;

    if (!email) {
        yield put(CombinedActions.authResetPasswordFailure('Please enter your email'));
        return;
    }

    const response = yield* call([api, api.resetPassword], { email, otp: numberOtp, newPassword });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(CombinedActions.authResetPasswordFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        yield put(CombinedActions.authResetPasswordSuccess());
        toast.success('Password reset successful!');

        navToLogin();
    }
}
