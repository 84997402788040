import React from 'react';
import { connect } from 'react-redux';
import { Location } from 'history';

import { RootState } from 'redux/store';
import CTAButton from 'components/CTAButton';
import NavBarNavButton from 'components/NavBarNavButton';
import UserProfileDropdown from 'components/UserProfileDropdown';
import MobileMenuDropdown from 'components/MobileMenuDropdown';
import Utils from 'lib/Utils';
import NavActions from 'lib/NavActions';
import CombinedSelectors from 'redux/CombinedSelectors';
import { ContainerConstants, NavigationConstants } from 'lib/Constants';
import VisionCooperLogo from '../../assets/images/cooper-vision-logo.webp';

import NavBarStyles from './_NavBarStyles.module.scss';

interface NavBarProps {
    location: Location | null | undefined;
    authToken: string;
}

const NavBar = (props: NavBarProps): JSX.Element => {
    const {
        location,
        authToken,
    } = props;

    let isAuthenticated = authToken;

    if (!isAuthenticated) isAuthenticated = Utils.Auth.getAuthToken() || '';

    const {
        navToHome,
        navToLogin,
        navToHomeHowToJoinSection,
        navToHomeFAQSection,
        navToProducts,
        navToTermsAndConditions,
    } = NavActions;

    const {
        homePage: {
            howToJoinContainerId,
            faqContainerId,
        },
    } = ContainerConstants;

    const {
        productsPage,
        termsAndConditionsPage,
    } = NavigationConstants;

    return (
        <nav className={NavBarStyles.container}>
            <button
                type='button'
                onClick={navToHome}
                className={NavBarStyles['logo-button']}
                aria-label='cooper-vision-logo'
            >
                <img
                    src={VisionCooperLogo}
                    alt='coopervision logo'
                />
            </button>

            <div className={NavBarStyles.subcontainer}>
                <NavBarNavButton
                    label='How to Join'
                    onClick={navToHomeHowToJoinSection}
                    href={`/#${howToJoinContainerId}`}
                />

                <NavBarNavButton
                    label='FAQ'
                    onClick={navToHomeFAQSection}
                    href={`/#${faqContainerId}`}
                />

                <NavBarNavButton
                    label='Products'
                    onClick={navToProducts}
                    href={productsPage}
                />

                <NavBarNavButton
                    label='Terms & Conditions'
                    onClick={navToTermsAndConditions}
                    href={termsAndConditionsPage}
                    isActive={location?.pathname === termsAndConditionsPage}
                />

                {isAuthenticated
                    ? (
                        <UserProfileDropdown />
                    )
                    : (
                        <CTAButton
                            onClick={navToLogin}
                            label='Login / Sign Up'
                            className={NavBarStyles['login-or-signup-button']}
                        />
                    )}
            </div>

            <div className={NavBarStyles['mobile-menu']}>
                <MobileMenuDropdown />
            </div>
        </nav>
    );
};

const mapStateToProps = (state: RootState) => ({
    location: state.router.location,
    authToken: CombinedSelectors.auth.getAuthToken(state.auth),
});

export default connect(mapStateToProps, null)(NavBar);
