import { put, call, takeEvery } from 'typed-redux-saga/macro';

import { SagaWatcherReturnType } from 'sagas/types';

import AuthGateway from 'api/Auth';

import CombinedActions from 'redux/CombinedActions';
import NavActions from 'lib/NavActions';
import { LoginReduxParams } from 'redux/slices/auth/types';
import { GatewayResponseStatus } from 'api/types/types';
import { PayloadAction } from '@reduxjs/toolkit';
import Utils from 'lib/Utils';
import { toast } from 'react-toastify';

export default function* watchLogin(api: AuthGateway): SagaWatcherReturnType {
    yield takeEvery('auth/authLoginAttempt', handleLogin, api);
}

function* handleLogin(api: AuthGateway, data: PayloadAction<LoginReduxParams>) {
    const { email, password } = data.payload;

    if (!email || !password) {
        yield put(CombinedActions.authLoginFailure('Please enter your email and password'));
        return;
    }

    const response = yield* call([api, api.login], { email, password });

    if (response.status === GatewayResponseStatus.Error) {
        yield put(CombinedActions.authLoginFailure(response.message));
        return;
    } if (response.status === GatewayResponseStatus.Success) {
        const {
            data: {
                token,
                user: { fullName },
            },
        } = response;

        yield put(CombinedActions.authLoginSuccess(token));
        Utils.Auth.storeAuthToken(token);
        toast.success('Successfully logged in!');

        yield put(CombinedActions.profileSetProfilePageCurrentTab('Upload Receipt'));
        yield put(CombinedActions.profileSetUserFullName(fullName));

        NavActions.navToProfile();
    }
}
