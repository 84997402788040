import React, { useEffect, useState } from 'react';
import { Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import CTAButton from 'components/CTAButton';

import { connect } from 'react-redux';

import { AppDispatch, RootState } from 'redux/store';
import CombinedActions from 'redux/CombinedActions';
import { GetUserInfoAPIResponse } from 'api/ProfileBase';
import CombinedSelectors from 'redux/CombinedSelectors';
import { ChangePasswordReduxParams, UpdateUserInfoReduxParams } from 'redux/slices/profile/types';
import { ContainerConstants } from 'lib/Constants';
import { ReactComponent as ArrowLeftSVG } from '../../../../assets/icons/arrow-left.svg';
import SettingStyles from './_SettingStyles.module.scss';

interface SettingsProps {
    userInfo: GetUserInfoAPIResponse;
    userInfoIsLoading: boolean;
    getUserInfo: () => void;

    updateUserInfoIsLoading: boolean;
    updateUserInfoError: string;
    updateUserInfo: (params: UpdateUserInfoReduxParams) => void;

    changePasswordIsLoading: boolean;
    changePasswordError: string;
    changePassword: (params: ChangePasswordReduxParams) => void;

    changePasswordStatus: boolean;
    setChangePasswordStatus: (status: boolean) => void

    resetError: () => void;
}

const Settings = (props: SettingsProps): JSX.Element => {
    const {
        userInfo,
        userInfoIsLoading,
        getUserInfo,
        updateUserInfoIsLoading,
        updateUserInfoError,
        updateUserInfo,
        changePasswordIsLoading,
        changePasswordError,
        changePassword,
        resetError,
        changePasswordStatus,
        setChangePasswordStatus,
    } = props;

    const {
        fullName,
        email,
        phoneNumber,
    } = userInfo;

    const { profilePage: {
        changePasswordSuccessBody,
        changePasswordSuccessHeader,
    } } = ContainerConstants;

    const [username, setUsername] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [isChangePassword, setIsChangePassword] = useState(false);

    const [usernameInvalid, setUsernameInvalid] = useState(false);
    const [contactNoInvalid, setContactNoInvalid] = useState(false);

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [oldPasswordInvalid, setOldPasswordInvalid] = useState('');
    const [newPasswordInvalid, setNewPasswordInvalid] = useState('');
    const [confirmPasswordInvalid, setConfirmPasswordInvalid] = useState('');

    useEffect(() => {
        getUserInfo();
    }, []);

    useEffect(() => {
        setUsername(fullName);
        setContactNo(phoneNumber);
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        setOldPasswordInvalid('');
        setNewPasswordInvalid('');
        setConfirmPasswordInvalid('');
        resetError();
    }, [fullName, phoneNumber, isChangePassword]);
    // ? for adding the error from api to the input
    useEffect(() => {
        if (changePasswordError) {
            setOldPasswordInvalid(changePasswordError);
        }
    }, [changePasswordError, isChangePassword]);
    // ? for removing error message if user input something
    useEffect(() => {
        if (oldPassword) {
            setOldPasswordInvalid('');
        }
        if (newPassword) {
            setNewPasswordInvalid('');
        }
        if (confirmPassword) {
            setConfirmPasswordInvalid('');
        }
    }, [oldPassword, newPassword, confirmPassword, isChangePassword]);

    const handleChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        if (e.target.value === '') {
            setUsername('');
            setUsernameInvalid(true);
        } else {
            setUsername(inputValue);
            setUsernameInvalid(false);
        }
    };

    const handleChangeContact = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        const regex = /^[0-9\b]+$/; // Regex pattern to match numbers

        if (inputValue === '') {
            setContactNo('');
            setContactNoInvalid(true);
        } else if (regex.test(inputValue) && inputValue.length <= 12) {
            setContactNo(inputValue);
            setContactNoInvalid(false);
        }
    };

    const handleSaveClick = () => {
        const params = {
            fullName: username,
            phoneNumber: contactNo,
        };

        if (!username) {
            setUsernameInvalid(true);
        } else if (!contactNo) {
            setContactNoInvalid(true);
        } else {
            setUsernameInvalid(false);
            setContactNoInvalid(false);
            updateUserInfo(params);
        }
    };

    const handleDiscardClick = () => {
        setUsername(fullName);
        setContactNo(phoneNumber);
    };

    const handleConfirmClick = () => {
        const params = {
            oldPassword,
            newPassword,
            confirmPassword,
        };

        if (oldPassword && newPassword && confirmPassword) {
            if (newPassword !== confirmPassword) {
                setNewPasswordInvalid('Passwords do not match');
                setConfirmPasswordInvalid('Passwords do not match');
            } else if (newPassword.length < 6) {
                setNewPasswordInvalid('Password must be at least 6 characters long');
            } else {
                changePassword(params);
                setNewPasswordInvalid('');
                setOldPasswordInvalid('');
                setConfirmPasswordInvalid('');
            }
        }

        if (!oldPassword) {
            setOldPasswordInvalid('Current password cannot be blank');
        }
        if (!newPassword) {
            setNewPasswordInvalid('New password cannot be blank');
        }
        if (!confirmPassword) {
            setConfirmPasswordInvalid('Confirm password cannot be blank');
        }
    };

    const renderUserInfo = () => {
        return (
            <Form className={SettingStyles['form-table']}>
                <FormGroup className={SettingStyles['form-group']}>
                    <Label
                        className={SettingStyles.label}
                        for='username'
                    >
                        Username
                    </Label>

                    <Input
                        type='text'
                        name='username'
                        value={username}
                        onChange={(e) => handleChangeUsername(e)}
                        invalid={usernameInvalid || undefined}
                    />

                    <FormFeedback
                        invalid={usernameInvalid ? 'true' : undefined}
                        className={SettingStyles['form-feedback']}
                    >
                        Username cannot be blank!
                    </FormFeedback>
                </FormGroup>
                <FormGroup className={SettingStyles['form-group']}>
                    <Label
                        className={SettingStyles.label}
                        for='email'
                    >
                        Email
                    </Label>

                    <Input
                        type='text'
                        name='email'
                        value={email}
                        disabled
                    />
                </FormGroup>
                <FormGroup className={SettingStyles['form-group']}>
                    <Label
                        className={SettingStyles.label}
                        for='contact'
                    >
                        Contact No.
                    </Label>

                    <Input
                        type='text'
                        name='contact'
                        value={contactNo}
                        onChange={(e) => handleChangeContact(e)}
                        invalid={contactNoInvalid || undefined}
                    />
                    <FormFeedback
                        invalid={contactNoInvalid ? 'true' : undefined}
                        className={SettingStyles['form-feedback']}
                    >
                        Contact cannot be blank!
                    </FormFeedback>
                </FormGroup>
                <FormGroup className={SettingStyles['form-group']}>
                    <Label
                        className={SettingStyles.label}
                        for='password'
                    >
                        Password
                    </Label>

                    <CTAButton
                        style={{ width: '80%', fontSize: '20px' }}
                        label='Change Password'
                        onClick={() => setIsChangePassword(true)}
                        outline
                    />
                </FormGroup>
            </Form>
        );
    };

    const renderChangePassword = () => {
        return (
            <>
                <CTAButton
                    label='Change Password'
                    onClick={() => setIsChangePassword(false)}
                    style={{ width: '50%', fontSize: '36px', margin: '0 0 20px 0' }}
                    icon={<ArrowLeftSVG width={35} height={35} />}
                    ghost
                />
                <Form className={SettingStyles['form-table']}>
                    <FormGroup className={SettingStyles['form-group']}>
                        <Label
                            className={SettingStyles.label}
                            for='currentPassword'
                        >
                            Current Password*
                        </Label>

                        <Input
                            type='password'
                            name='currentPassword'
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.target.value)}
                            invalid={oldPasswordInvalid ? true : undefined}
                        />
                        <FormFeedback
                            invalid={oldPasswordInvalid ? 'true' : undefined}
                            className={SettingStyles['form-feedback']}
                        >
                            {oldPasswordInvalid}
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup />
                    <FormGroup className={SettingStyles['form-group']}>
                        <Label
                            className={SettingStyles.label}
                            for='newPassword'
                        >
                            New Password
                        </Label>

                        <Input
                            type='password'
                            name='newPassword'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            invalid={newPasswordInvalid ? true : undefined}
                        />

                        <FormFeedback
                            invalid={newPasswordInvalid ? 'true' : undefined}
                            className={SettingStyles['form-feedback']}
                        >
                            {newPasswordInvalid}
                        </FormFeedback>
                    </FormGroup>
                    <FormGroup className={SettingStyles['form-group']}>
                        <Label
                            className={SettingStyles.label}
                            for='confirmPassword'
                        >
                            Confirm New Password
                        </Label>

                        <Input
                            type='password'
                            name='confirmPassword'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            invalid={confirmPasswordInvalid ? true : undefined}
                        />
                        <FormFeedback
                            invalid={confirmPasswordInvalid ? 'true' : undefined}
                            className={SettingStyles['form-feedback']}
                        >
                            {confirmPasswordInvalid}
                        </FormFeedback>
                    </FormGroup>
                </Form>
            </>
        );
    };

    const renderConfirmChangePassword = () => {
        return (
            <div
                style={{ display: !changePasswordStatus ? 'none' : '' }}
                className={SettingStyles['password-changed-text-container']}
            >
                <p className={SettingStyles['password-changed-header']}>
                    {changePasswordSuccessHeader}
                </p>

                <p className={SettingStyles['password-changed-body']}>
                    {changePasswordSuccessBody}
                </p>
                <CTAButton
                    label='Complete'
                    onClick={() => [setChangePasswordStatus(false), setIsChangePassword(false)]}
                    isLoading={changePasswordIsLoading}
                    className={SettingStyles['complete-button']}
                />
            </div>
        );
    };

    const renderButtonContainer = () => {
        if (changePasswordStatus) {
            return null;
        }
        if (isChangePassword) {
            return (
                <div className={SettingStyles['button-container']}>
                    <CTAButton
                        label='Confirm'
                        onClick={handleConfirmClick}
                        style={{ fontSize: '22px' }}
                        isLoading={changePasswordIsLoading}
                    />
                </div>
            );
        }
        return (
            <div className={SettingStyles['button-container']}>
                <CTAButton
                    label='Save Changes'
                    onClick={handleSaveClick}
                    isLoading={updateUserInfoIsLoading}
                />
                <CTAButton
                    label='Discard Changes'
                    onClick={handleDiscardClick}
                    outline
                />
            </div>
        );
    };

    const renderLogic = () => {
        if (changePasswordStatus) {
            return renderConfirmChangePassword();
        }
        if (isChangePassword) {
            return renderChangePassword();
        }
        return renderUserInfo();
    };

    return (
        <div className={SettingStyles.container}>
            {renderLogic()}
            <br />
            {renderButtonContainer()}
        </div>
    );
};
const mapStateToProps = (state: RootState) => ({
    userInfo: CombinedSelectors.profile.getUserInfo(state.profile),
    userInfoIsLoading: CombinedSelectors.profile.getUserInfoAttempting(state.profile),
    updateUserInfoIsLoading: CombinedSelectors.profile.updateUserInfoAttempting(state.profile),
    updateUserInfoError: CombinedSelectors.profile.updateUserInfoError(state.profile),
    changePasswordIsLoading: CombinedSelectors.profile.changePasswordAttempting(state.profile),
    changePasswordError: CombinedSelectors.profile.changePasswordError(state.profile),
    changePasswordStatus: CombinedSelectors.profile.getChangePasswordStatus(state.profile),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    getUserInfo: () => dispatch(CombinedActions.profileGetUserInfoAttempt()),
    updateUserInfo: (params: UpdateUserInfoReduxParams) => dispatch(CombinedActions.profileUpdateUserInfoAttempt(params)),
    changePassword: (params: ChangePasswordReduxParams) => dispatch(CombinedActions.profileChangePasswordAttempt(params)),
    resetError: () => dispatch(CombinedActions.profileResetSettingsErrors()),
    setChangePasswordStatus: (status: boolean) => dispatch(CombinedActions.profileSetChangePasswordStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
